import React from 'react';
import { Grid2, TextField, Button, Typography, Box } from '@mui/material';
import { sanitizeProductName, validateFileType } from '../utils/inputSanitizer';

const FileUpload = ({ productName, setProductName, pdfFile, setPdfFile }) => {
  const handleFileChange = (e) => {
    if (e.target.files[0] && validateFileType(e.target.files[0])) {
      setPdfFile(e.target.files[0]);
    } else {
      alert('Invalid file type. Please upload a PDF file.');
    }
  };

  return (
    <Grid2 container spacing={2} alignItems="flex-end">
      <Grid2 size ={{ xs:12, md:5}}>
        <TextField
          fullWidth
          label="分析対象（商品名や一般名を推奨）（50文字以内）"
          value={productName}
          onChange={(e) => setProductName(sanitizeProductName(e.target.value))}
          margin="normal"
        />
      </Grid2>
      <Grid2 size ={{ xs:2, md:2, lg:2}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-end' }}>
          <input
            accept="application/pdf"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" fullWidth>
              Upload PDF
            </Button>
          </label>
          <Typography variant="body2" sx={{ mt: 1, minHeight: '1.5em' }}>
            {pdfFile ? pdfFile.name : 'No file selected'}
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default FileUpload;