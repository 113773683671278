import React, { useState } from 'react';
import { IconButton, Tooltip, Alert } from '@mui/material';
import { Download } from 'lucide-react';
import { useFunctionTree } from '../../FunctionTreeContext';

const ImpactAnalysisDownload = ({ target, disabled }) => {
  const { authFetch } = useFunctionTree();
  const [isDownloading, setIsDownloading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleDownload = async () => {
    if (isDownloading || !target) return;
    setIsDownloading(true);

    try {
      const encodedTarget = encodeURIComponent(target);
      const response = await authFetch(
        `/export/impact-analysis/${encodedTarget}`,
        {
          headers: {
            'Accept': 'text/html',
          },
          responseType: 'blob',
        }
      );

      const blob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      let filename = `影響分析結果_${target}.html`;

      if (contentDisposition) {
        const filenameRegex = /filename\*=UTF-8''([^;\s]+)/i;
        const matches = contentDisposition.match(filenameRegex);
        if (matches && matches[1]) {
          try {
            filename = decodeURIComponent(matches[1]);
          } catch (e) {
            console.error('Error decoding filename:', e);
          }
        }
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setAlertSeverity('success');
      setAlertMessage('ファイルのダウンロードが完了しました');
      setShowAlert(true);
    } catch (error) {
      console.error('Download error:', error);
      setAlertSeverity('error');
      setAlertMessage('ダウンロードに失敗しました。もう一度お試しください。');
      setShowAlert(true);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Tooltip title="HTML形式でダウンロード">
        <span>
          <IconButton
            onClick={handleDownload}
            disabled={disabled || isDownloading || !target}
            sx={{
              ml: 1,
              opacity: (disabled || isDownloading || !target) ? 0.5 : 1,
              '&:hover': { bgcolor: 'grey.100' },
            }}
            aria-label="影響分析結果をダウンロード"
          >
            <Download className="h-5 w-5" />
          </IconButton>
        </span>
      </Tooltip>
      
      {showAlert && (
        <Alert
          sx={{ mt: 2 }}
          severity={alertSeverity}
          onClose={() => setShowAlert(false)}
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
};

export default ImpactAnalysisDownload;