import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid2, Button, Typography, Paper, CircularProgress } from '@mui/material';
import { APIEndpoints } from '../../config';
import { useParamDiagram } from '../../ParamDiagramContext';
import FunctionTree from '../FunctionTree';
import CommonizedRequirementsTable from './CommonizedRequirementsTable'; 
import ParameterDiagram from './ParameterDiagram';
import { useFunctionTree } from '../../FunctionTreeContext';
import RequirementDownload from '../Download/RequirementDownload'

const RequirementExtraction = ({ functionTreeData }) => {
    const [selectedSubFunction, setSelectedSubFunction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [commonizationProcess, setCommonizationProcess] = useState(null);
    const [commonizedFunctionalRequirements, setCommonizedFunctionalRequirements] = useState(null);
    const [dataReady, setDataReady] = useState(false);

    const {
      selectedPDiaTarget,
      setSelectedPDiaTarget,
      childFunctionDetails,
      setChildFunctionDetails,
      isPDiagramMode,
      setIsPDiagramMode,
      handlePDiagramModeToggle
    } = useParamDiagram();

    const {user, authFetch, refreshTokenBalance} = useFunctionTree();
  
    // subFunctionの下のfunctionDetailの抽出
    const extractFunctionDetails = (functionTreeData, parentName, name) => {
      for (const mainFunction of functionTreeData.function_tree) {
        if (mainFunction.main_function === parentName) {
          for (const subFunction of mainFunction.sub_functions) {
            if (subFunction.sub_function === name) {
              return subFunction.details;
            }
          }
        }
      }
      return null;
    };

    const extractChildFunctionDetails = (subFunction) => {
      const details = {};
      if (subFunction && subFunction.details) {
        subFunction.details.forEach(detail => {
          details[detail.function_level0] = detail.説明 || '';
        });
      }
      return details;
    };
  

    // 選択したSubFunctionノードをfuncとして渡す
    const handleSelectSubFunction = (func) => {
      setError(null);
      // funcがnullの場合（選択解除時）の処理
      if (func === null) {
        setSelectedSubFunction(null);
        setCommonizationProcess(null);
        setCommonizedFunctionalRequirements(null);
        setChildFunctionDetails({}); // Clear function details
        return;// これ以降の処理を行わない
      }

      // サブ機能かどうかを判定
      if (func.nodeType === 'sub_function') {
        // 現在選択されているサブ機能と同じものが選択された場合（選択解除）
        if (selectedSubFunction && selectedSubFunction.id === func.id) {
          setSelectedSubFunction(null);
          setCommonizationProcess(null);
          setCommonizedFunctionalRequirements(null);
          setChildFunctionDetails({}); // Clear function details
        } else {
          // 新しいサブ機能が選択された場合
          setSelectedSubFunction(func);
        const subFunctionData = functionTreeData.function_tree
          .find(mainFunc => mainFunc.main_function === func.parentName)
          ?.sub_functions.find(subFunc => subFunc.sub_function === func.name);
        const details = extractChildFunctionDetails(subFunctionData);
        setChildFunctionDetails(details);
        fetchCommonizedRequirements(func);
        }
      } else {
        // サブ機能でない場合の処理
        setSelectedSubFunction(null);
        setCommonizationProcess(null);
        setCommonizedFunctionalRequirements(null);
        setChildFunctionDetails({}); 
      }
    };

    // 共通化済み要件を取得する
    // fetchCommonizedRequirements 関数を更新します
    const fetchCommonizedRequirements = useCallback(async (func) => {
      if (!func || !func.name || !func.parentName) {
        console.warn('Invalid function data for fetching requirements');
        return;
      }
      setLoading(true);
      setError(null);
      try {
        // Fetch commonization process
        const processData = await authFetch(`/api/commonized-requirements/${functionTreeData.product_name}/${func.parentName}/${func.name}?user_id=${user.uid}`);
        setCommonizationProcess(processData);

        // Extract function details
        const functionDetails = extractFunctionDetails(functionTreeData, func.parentName, func.name);
        if (!functionDetails) {
          throw new Error('Function details not found');
        }

        // Fetch commonized functional requirements for each function_level0
        const commonizedRequirements = {};
        for (const detail of functionDetails) {
          try {
            const requirementData = await authFetch(`/api/commonized-functional-requirements/${
              encodeURIComponent(functionTreeData.product_name)}/${
              encodeURIComponent(func.parentName)}/${
              encodeURIComponent(func.name)}/${
              encodeURIComponent(detail.function_level0)}?user_id=${user.uid}`);
            commonizedRequirements[detail.function_level0] = requirementData;
          } catch (error) {
            console.warn(`Failed to fetch requirements for ${detail.function_level0}:`, error);
          }
        } 
        setCommonizedFunctionalRequirements(commonizedRequirements);
        setDataReady(true);
      } catch (error) {
        console.error('Error fetching commonized requirements:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }, [ functionTreeData, authFetch]);

    // 共通化要件の生成
    const handleAnalyze = useCallback(async () => {
      if (!selectedSubFunction || !selectedSubFunction.name || !selectedSubFunction.parentName) {
        console.warn('Invalid or no sub-function selected for analysis');
        setError('サブ機能が選択されていないか、無効なサブ機能が選択されています。');
        return;
      }
    
      setLoading(true);
      setError(null);
      try {
        const data = await authFetch(`${APIEndpoints.COMMONIZE_REQUIREMENTS}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: functionTreeData.product_name,
            main_function_name: selectedSubFunction.parentName,
            sub_function_name: selectedSubFunction.name
          }),
        });

        if (data.status === 'success') {
          await refreshTokenBalance();
          await fetchCommonizedRequirements(selectedSubFunction);
        } else {
          throw new Error('Unexpected data format');
        }
      } catch (error) {
        console.error('Error analyzing requirements:', error);
        setError([
          '要件の分析に失敗しました:',
          '構成する機能の故障モード分析が揃っていません。',
          '不足している機能を個別に分析するか、',
          'グループ故障モード分析を実行してください。'
        ]);
      } finally {
        setLoading(false);
      }
    },[selectedSubFunction, functionTreeData.product_name, authFetch, fetchCommonizedRequirements]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid2 container spacing={2} sx={{ width: '100%' }}>
        {/* P-Diagramの対象機能の選択画面　兼　共通化要件の生成画面 */}
        {!isPDiagramMode ? (
          <>
            <Grid2 item size={{ xs:12, md:6, lg:4}}>
                <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
                    <Typography variant="h6">機能ツリー</Typography>
                    <FunctionTree 
                    data={functionTreeData} 
                    onSelectFunction={handleSelectSubFunction} 
                    popperPlacement="right-start"
                    selectMode="subFunction"
                    />
                </Paper>
            </Grid2>
            <Grid2 item size={{ xs:12, md:6, lg:8}}>
              <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 1 }}>機能の要件抽出</Typography>
                <RequirementDownload
                  productName={functionTreeData.product_name}
                  mainFunctionName={selectedSubFunction?.parentName || ''}
                  subFunctionName={selectedSubFunction?.name || ''}
                  disabled={loading || !commonizationProcess || !commonizedFunctionalRequirements}
                />
              </Box>
                  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <Button 
                          variant="contained" 
                          onClick={handleAnalyze} 
                          disabled={!selectedSubFunction || loading}
                          sx={{ height: '36px' }}
                      >
                          {loading ? 'Analyzing...' : '要件の抽出'}
                      </Button>
                      <Button
                          variant="contained"
                          onClick={handlePDiagramModeToggle}
                          sx={{ 
                              height: '36px', 
                              bgcolor: '#28B263', 
                              '&:hover': { bgcolor: '#1D8148' } 
                          }}
                          disabled={!selectedPDiaTarget}
                      >
                          Pダイアグラムに移行
                      </Button>
                  </Box>
                {/* 機能ツリーで選択した機能の共通化済み要件が存在する場合はテーブルで表示 */}
                {error && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      {Array.isArray(error) ? (
                        error.map((line, index) => (
                          <Typography key={index} color="error" variant="body2">
                            {line}
                          </Typography>
                        ))
                      ) : (
                        <Typography color="error" variant="body2">
                          {error}
                        </Typography>
                      )}
                    </Box>
                  )}
                {loading ? (
                  <CircularProgress />
                ) : dataReady && commonizationProcess && commonizedFunctionalRequirements ? (
                  <CommonizedRequirementsTable                  
                    commonizationProcess={commonizationProcess}
                    commonizedFunctionalRequirements={commonizedFunctionalRequirements}
                    productName={functionTreeData.product_name}
                    mainFunctionName={selectedSubFunction ? selectedSubFunction.parentName : ''}
                    subFunctionName={selectedSubFunction ? selectedSubFunction.name: ''}
                  />
                ) : (
                  <Typography>サブ機能を選択してください。</Typography>
                )}
              </Paper>
            </Grid2>
          </>
        ) : (
          <Grid2 item xs={12}>
            <ParameterDiagram />
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default RequirementExtraction;