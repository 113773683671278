import React, { createContext, useContext, useState } from 'react';

// Context オブジェクトを作成
const ParamDiagramContext = createContext();

// Provider コンポーネントを定義
export const ParamDiagramProvider = ({ children }) => {
  // 共有したい状態を useState で定義
  const [selectedPDiaTarget, setSelectedPDiaTarget] = useState(null);
  const [childFunctionDetails, setChildFunctionDetails] = useState({});
  const [isPDiagramMode, setIsPDiagramMode] = useState(false);
  const [highlightedFunction, setHighlightedFunction] = useState(null);
  
  // パラメータダイヤグラム表示画面への切り替え
  const handlePDiagramModeToggle = () => {
    setIsPDiagramMode(prev => !prev);
    // if (isPDiagramMode) {
    //   setSelectedPDiaTarget(null);
    // }
  };
 
  // Provider コンポーネントを返す
  return (
    <ParamDiagramContext.Provider value={{
      selectedPDiaTarget,
      setSelectedPDiaTarget,
      childFunctionDetails,
      setChildFunctionDetails,
      isPDiagramMode,
      setIsPDiagramMode,
      handlePDiagramModeToggle,
      highlightedFunction,
      setHighlightedFunction,
    }}>
      {children}
    </ParamDiagramContext.Provider>
  );
};

// カスタムフックを作成して Context の使用を簡略化
export const useParamDiagram = () => {
  const context = useContext(ParamDiagramContext);
  if (context === undefined) {
    throw new Error('useParamDiagram must be used within a ParamDiagramProvider');
  }
  return context;
};