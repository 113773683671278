// CustomGroupNode.jsx
import React from 'react';
import { Handle, Position } from 'reactflow';

const CommonReqGroupNode = ({ data }) => {
    return (
      <div style={{
        padding: '5px',
        borderRadius: '15px',
        background: 'rgba(255, 0, 0, 0.2)',
        border: '1px solid #ddd',
        width: data.width || 1000,
        height: data.height || 110,
        zIndex:0,
      }}>
        <Handle type="target" position={Position.Top} />
        <div style={{
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        textAlign: 'center',
      }}>
        {data.label}
      </div>
      </div>
    );
  };
//          <Handle type="source" position={Position.Bottom} />


  const IndReqGroupNode = ({ data }) => {
  return (
    <div style={{
      padding: '5px',
      borderRadius: '15px',
      background: data.background || 'rgba(209, 257, 235, 0.8)',
      border: '1px solid #ddd',
      width: data.width || 200,
      height: data.height || 1000,
      zIndex:0,
    }}>
      <Handle type="target" position={Position.Top} />
      <div style={{
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        textAlign: 'center',
      }}>
        {data.label}
      </div>
    </div>
  );
};



export { CommonReqGroupNode, IndReqGroupNode };