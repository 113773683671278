import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid2,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import {
  AccountTree as TreeIcon,
  Warning as AlertIcon,
  Refresh as RefreshIcon,
  Hub as DiagramIcon,
  Download as DownloadIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

import TokenIntroduction from './TokenIntroduction';

// TabPanel component for displaying tab content
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`feature-tabpanel-${index}`}
      aria-labelledby={`feature-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const IntroductionPage = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const mainFeatures = [
    {
      title: "機能ツリー生成",
      description: "製品仕様書から自動で機能ツリーを生成します。各機能の階層関係を視覚的に把握でき、製品全体の機能構造を俯瞰することができます。",
      icon: <TreeIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
      image: "/images/feature_introduction/FunctionTree_feature.jpg",
      details: [
        "PDFファイルからの追加情報の取り込み",
        "階層構造の視覚化"
      ]
    },
    {
      title: "故障モード分析",
      description: "各機能の潜在的な故障モードを自動で分析し、その影響と原因を抽出します。信頼性工学の知見に基づいた包括的な故障分析が可能です。",
      icon: <AlertIcon sx={{ fontSize: 40, color: '#d32f2f' }} />,
      image: "/images/feature_introduction/FaiureMode_feature.jpg",
      details: [
        "単一故障モード分析",
        "グループ故障モード分析",
        "故障の影響度と故障原因の自動抽出"
      ]
    },
    {
      title: "設計変更影響分析",
      description: "設計変更が製品全体に与える影響を包括的に分析します。変更による波及効果を事前に把握し、効率的な設計変更管理を支援します。",
      icon: <RefreshIcon sx={{ fontSize: 40, color: '#388e3c' }} />,
      image: "/images/feature_introduction/ImpactAnalysis_feature.jpg",
      details: [
        "関連機能の自動特定",
      ]
    },
    {
      title: "パラメータダイアグラム",
      description: "各機能のパラメータ関係を整理し、Input-Process-Output形式で表現します。制御因子やノイズ因子も含めた包括的な機能分析が可能です。",
      icon: <DiagramIcon sx={{ fontSize: 40, color: '#7b1fa2' }} />,
      image: "/images/feature_introduction/ParameterDiagram_feature.jpg",
      details: [
        "Input/Process/Output構造の自動生成",
        "Control FactorとNoise Factorの自動分析",
        "パラメータ間の関係性把握"
      ]
    }
  ];

  const supportFeatures = [
    {
      title: "分析結果のエクスポート",
      description: "生成された分析結果をExcel形式でエクスポートできます。標準的なスプレッドシートソフトウェアで開くことができ、さらなる分析や報告書作成に活用できます。",
      icon: <DownloadIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
      image: "/images/feature_introduction/export_feature.png",
      details: [
        "HTML形式でのエクスポート",
        "Excel互換形式",
      ]
    }
  ];

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      {/* AppBar */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography 
              variant="h6" 
              component="div"
              sx={{ fontFamily: 'Goldman, sans-serif', mr: 2 }}
            >
              G-FASS
            </Typography>
            <img 
              src="/images/MC_newlogo_2.jpg" 
              alt="Mediocritas logo" 
              style={{ height: '24px', width: 'auto' }} 
            />
          </Box>
          <Button color="inherit" onClick={() => navigate('/')}>ホーム</Button>
          <Button color="inherit" onClick={() => navigate('/pricing')}>料金プラン</Button>
          <Button 
            color="inherit" 
            href="https://www.mediocritas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </Button>
          <Button color="inherit" onClick={() => navigate('/contact')}>お問い合わせ</Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={() => navigate('/app')}
            sx={{ ml: 2 }}
          >
            アプリを始める
          </Button>
        </Toolbar>
      </AppBar>

       {/* Hero Section */}
       <Box 
        sx={{ 
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
          textAlign: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            G-FASSの機能
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            GPTを活用した製品開発支援システム
          </Typography>
          <Button
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/app')}
            sx={{ mt: 4 }}
          >
            アプリを始める
          </Button>
        </Container>
      </Box>

      {/* Features Section with Tabs */}
      <Container sx={{ py: 4 }} maxWidth="lg">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            centered
            sx={{
              '& .MuiTab-root': {
                fontSize: '1.1rem',
                fontWeight: 'medium',
              }
            }}
          >
            <Tab label="主要機能" />
            <Tab label="補助機能" />
            <Tab label="トークンシステム" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Grid2 container spacing={6}>
            {mainFeatures.map((feature, index) => (
              <Grid2 item xs={12} key={index}>
                <Paper 
                  elevation={2}
                  sx={{ 
                    p: 4,
                    transition: 'all 0.3s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    }
                  }}
                >
                  <Grid2 container spacing={4} alignItems="center">
                    <Grid2 item xs={12} md={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {feature.icon}
                        <Typography variant="h4" sx={{ ml: 2, fontWeight: 'bold' }}>
                          {feature.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1" color="text.secondary" paragraph>
                        {feature.description}
                      </Typography>
                      <Grid2 container spacing={2}>
                        {feature.details.map((detail, idx) => (
                          <Grid2 item xs={6} key={idx}>
                            <Typography
                              variant="body2"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.secondary'
                              }}
                            >
                              • {detail}
                            </Typography>
                          </Grid2>
                        ))}
                      </Grid2>
                    </Grid2>
                    <Grid2 item xs={12} md={6}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="300"
                          image={feature.image}
                          alt={feature.title}
                          sx={{
                            objectFit: 'cover',
                            bgcolor: 'grey.100'
                          }}
                        />
                      </Card>
                    </Grid2>
                  </Grid2>
                </Paper>
              </Grid2>
            ))}
          </Grid2>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Grid2 container spacing={6}>
            {supportFeatures.map((feature, index) => (
              <Grid2 item xs={12} key={index}>
                <Paper 
                  elevation={2}
                  sx={{ 
                    p: 4,
                    transition: 'all 0.3s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6
                    }
                  }}
                >
                  <Grid2 container spacing={4} alignItems="center">
                    <Grid2 item xs={12} md={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {feature.icon}
                        <Typography variant="h4" sx={{ ml: 2, fontWeight: 'bold' }}>
                          {feature.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1" color="text.secondary" paragraph>
                        {feature.description}
                      </Typography>
                      <Grid2 container spacing={2}>
                        {feature.details.map((detail, idx) => (
                          <Grid2 item xs={6} key={idx}>
                            <Typography
                              variant="body2"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.secondary'
                              }}
                            >
                              • {detail}
                            </Typography>
                          </Grid2>
                        ))}
                      </Grid2>
                    </Grid2>
                    <Grid2 item xs={12} md={6}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="300"
                          image={feature.image}
                          alt={feature.title}
                          sx={{
                            objectFit: 'cover',
                            bgcolor: 'grey.100'
                          }}
                        />
                      </Card>
                    </Grid2>
                  </Grid2>
                </Paper>
              </Grid2>
            ))}
          </Grid2>
        </TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          <TokenIntroduction />
        </TabPanel>
        
      </Container>

      {/* CTA Section */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          py: 8,
          textAlign: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="h4" gutterBottom>
            製品開発を効率化しませんか？
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            G-FASSを使って、より効率的な製品開発プロセスを実現しましょう。
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/app')}
            sx={{ mt: 3 }}
          >
            無料で始める
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3, mt: 8 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 2,
            mb: 2
          }}>
            <Link to="/terms" component={Button} color="inherit">
              利用規約
            </Link>
            <Link to="/privacy" component={Button} color="inherit">
              プライバシーポリシー
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © 2024 G-FASS. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default IntroductionPage;