import React, { useState } from 'react';
import { Container, Box, Button, CircularProgress, Grid2, Typography, Paper } from '@mui/material';
import 'reactflow/dist/style.css';
import { auth } from './services/firebase';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { FunctionTreeProvider, useFunctionTree} from './FunctionTreeContext';
import { ParamDiagramProvider} from './ParamDiagramContext';
import { APIEndpoints } from './config';

import LandingPage from './components/LandingPage/LandingPage';
import TermsOfService from './components/LandingPage/TermsOfService';
import PrivacyPolicy from './components/LandingPage/PrivacyPolicy';
import SignInPage from './components/LandingPage/SignInPage';
import TokenBalance from './components/TokenManage/TokenBalance';
import PricingPage from './components/LandingPage/PricingPage';
import IntroductionPage from './components/LandingPage/Introduction';
import ContactPage from './components/LandingPage/ContactPage';

import FunctionTree from './components/FunctionTree';
import FileUpload from './components/FileUpload';
import SingleFailureModeAnalysis from './components/FailureModeAnalysis/SingleFailureModeAnalysis';
import GroupFailureModeAnalysis from './components/FailureModeAnalysis/GroupFailureModeAnalysis';
import DesignChangeImpact from './components/DesignChangeImpact';
import FunctionTreeVisualization from './components/forEdit/FunctionTreeVisualization'
import RequirementExtraction from './components/pdiagram/RequirementExtraction';

import DownloadFunctionTree from './components/Download/FunctionTreeDownload'



function AppContent() {
  const [productName, setProductName] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [functionTreeData, setFunctionTreeData] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('functionTree');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, loading, authFetch, refreshTokenBalance } = useFunctionTree();

  const navigate = useNavigate();

  // 前回作成した機能ツリーを読み込む
  const handleLoadPreviousTree = async () => {
    setIsLoading(true);
    try {

      const data = await authFetch(`${APIEndpoints.FUNCTION_TREE}?user_id=${user.uid}`);
      setFunctionTreeData(data);
      // Extract productName from the loaded data
      if (data.product_name) {
        setProductName(data.product_name);
      } else {
      console.warn('Product name not found in the loaded data');
      }
    } catch (error) {
      console.error('Error loading previous tree:', error);
      setError('Failed to load previous function tree. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // 機能ツリーを生成する
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) return;
    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('name', productName);
    formData.append('user_id', user.uid);
    if (pdfFile) {
      formData.append('document_file', pdfFile);
    }

    try {
      const data = await authFetch(`${APIEndpoints.FUNCTION_TREE}?user_id=${user.uid}`, {
        method: 'POST',
        body: formData,
      });
      await refreshTokenBalance();
      setFunctionTreeData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to generate function tree. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // 要件画面に移る
  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  // 初期画面に戻る
  const handleResetToInitialScreen = () => {
    setFunctionTreeData(null);
    setProductName('');
    setPdfFile(null);
    setActiveTab('functionTree');
    setIsEditMode(false);
  };

  const handlePortalAccess = () => {
    // Stripeのno-codeポータルへリダイレクト
    window.location.href = 'https://billing.stripe.com/p/login/00g28g1K62mc0Lu288';
  };

  if (!user) {
    return <SignInPage />;
  }

  return (
    <Container >
      <Grid2 container width={'100%'} alignItems="center" spacing={2} sx={{ mt: 3 }}>
      <Grid2 item xs>
          <Typography variant='h3' sx={{ fontFamily: 'Goldman, sans-serif' }}>
            G-FASS
          </Typography>
          <Typography variant='subtitle1' sx={{ fontSize: '1rem', lineHeight: 1 }}>
            GPT-Function Analysis Support System
            <Typography component="span" sx={{ fontSize: '0.67rem', ml: 1 }}>
              v0.3.5
            </Typography>
          </Typography>
        </Grid2>
      {/* Mediocritas Logo */}
      <Grid2 item>
        <img 
        src="\images\MC_newlogo_2.jpg" 
        alt="Mediocritas logo" 
        style={{ height: '60px', width: 'auto' }} 
        />
      </Grid2>

      {/* Actions Container */}
      <Grid2 item position='absolute' top='10px' right= '10px'>
        <Grid2 container alignItems="center" spacing={1}>
          <Grid2 item>
            {/* Token Balance */}
              <TokenBalance />
            {/* Pricing Button */}
          </Grid2>

          <Grid2 item>
            <Button 
              variant="contained" 
              onClick={() => navigate('/pricing')}
              sx={{ 
                bgcolor: '#A9A9A9', // シルバー色
                color: 'white',
                '&:hover': { 
                  bgcolor: '#808080' // ダークシルバー
                },
                mr : 1 
              }}
            >
              料金プラン
            </Button>
          </Grid2>


           {/* Plan Management Button */}
           <Grid2>
            <Button
              variant="contained"
              onClick={handlePortalAccess}
              aria-label="サブスクリプション管理画面を開く"
              sx={{ 
                bgcolor: '#A9A9A9', // シルバー色
                color: 'white',
                '&:hover': { 
                  bgcolor: '#808080' // ダークシルバー
                },
                mr : 3 
              }}
            >
              プラン管理
            </Button> 
          </Grid2>


           {/* Function Tree Button (Conditional) */}
            {functionTreeData && (
              <Grid2>
                <Button 
                  variant="contained" 
                  onClick={handleResetToInitialScreen}
                  sx={{ 
                    bgcolor: '#4D4D4D', 
                    '&:hover': { 
                      bgcolor: '#3D3D3D' 
                    },
                    mr:1 
                  }}
                >
                  機能ツリー生成
                </Button>
              </Grid2>
            )}

          {/* Logout Button */}
          <Grid2>
            <Button 
              variant="contained" 
              onClick={() => auth.signOut()} 
              sx={{ 
                bgcolor: '#4D4D4D', 
                '&:hover': { 
                  bgcolor: '#3D3D3D' 
                } 
              }}
            >
              ログアウト
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
    
      <Box sx={{ width: '100%', mt: 4 }}>
        {!functionTreeData && (
          <>
          <form onSubmit={handleSubmit}>
          <FileUpload 
            productName={productName}
            setProductName={setProductName}
            pdfFile={pdfFile}
            setPdfFile={setPdfFile}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Generate Function Tree
            </Button>
          </Box>
        </form>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
            <Button 
              variant="contained" 
              onClick={handleLoadPreviousTree} 
              disabled={isLoading}
            >
              前回の機能ツリー
            </Button>
          </Box>
        </>
        )}
        
        {functionTreeData && (
          <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
              <Button 
                variant="contained" 
                onClick={() => setActiveTab('functionTree')}
                sx={{ mr: 2, mb: 2 }}
                disabled={isEditMode}
              >
                機能ツリー確認
              </Button>

              <Button 
                variant="contained" 
                onClick={() => setActiveTab('singleFailureAnalysis')}
                sx={{ mr: 2, mb: 2 }}
                disabled={isEditMode}
              >
                単故障モード分析
              </Button>
              
              <Button 
              variant="contained" 
              onClick={() => {
                setActiveTab('groupFailureAnalysis');
              }}
              sx={{ mr: 2, mb: 2 }}
              disabled={isEditMode}
              >
                グループ故障モード分析
              </Button>
              
              <Button 
                variant="contained" 
                onClick={() => setActiveTab('designChangeImpact')}
                sx={{ mb: 2 }}
                disabled={isEditMode}
              >
                設計変更影響抽出
              </Button>

              {/* <Button 
                  variant="contained" 
                  onClick={handleEditModeToggle}
                  sx={{ height:'35px', bgcolor: '#FF0066', '&:hover': { bgcolor: '#D10054' } }}
                >
                  {isEditMode ? 'ツリーに戻る' : '要件マップに移行'}
              </Button> */}

              <Button 
                variant="contained" 
                onClick={() => setActiveTab('requirementExtraction')}
                sx={{ height:'35px', bgcolor: '#28B263', '&:hover': { bgcolor: '#1D8148' } }}
                disabled={isEditMode}
              >
                P-ダイアグラム
              </Button>
              
            </Box>
            {!isEditMode ? (
              <>
                {activeTab === 'functionTree' && (
                  <Box sx={{ width: '100%' }}>
                    <Grid2 container spacing={2} sx={{ width: '100%' }}>
                      <Grid2 item size={{ xs:12, md:8, lg:8}}>
                        <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            marginBottom: '1rem'
                          }}>
                            <Typography variant="h6" sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              marginRight: '0.5rem'
                            }}>
                              機能ツリー
                            </Typography>
                            <DownloadFunctionTree />
                          </Box>
                          <FunctionTree data={functionTreeData} popperPlacement="right-start"/>
                        </Paper>  
                      </Grid2> 
                    </Grid2>   
                  </Box>  
                )}
      
                {activeTab === 'singleFailureAnalysis' && (
                  <Box sx={{ width: '100%' }}>
                    <SingleFailureModeAnalysis 
                      functionTreeData={functionTreeData} 
                      productName={productName}
                    />
                  </Box>
                )}

                {activeTab === 'groupFailureAnalysis' && (
                  <Box sx={{ width: '100%' }}>
                    <GroupFailureModeAnalysis 
                      functionTreeData={functionTreeData} 
                      productName={productName}
                    />
                  </Box>
                )}
                      
                {activeTab === 'designChangeImpact' && (
                  <Box sx={{ width: '100%' }}>
                    <DesignChangeImpact functionTreeData={functionTreeData} popperPlacement="left-start"/>
                  </Box>
                )}

                {activeTab === 'requirementExtraction' && (
                  <RequirementExtraction functionTreeData={functionTreeData} />
                )}
              </>
            ) : (
              <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                <FunctionTreeVisualization/>
              </Box>
            )}
          </Box>
        )}
  
        {isLoading && <CircularProgress />}
      </Box>
    </Container>
);
}

function App() {
  return (
    <Router>
      <FunctionTreeProvider>
        <ParamDiagramProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/app" element={<AppContent />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/introduction" element={<IntroductionPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </ParamDiagramProvider>
      </FunctionTreeProvider>
    </Router>
  );
}
export default App;