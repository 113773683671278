import React, { useState, useMemo, useCallback } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { Box, Typography, Popper, Paper } from '@mui/material';
import { useParamDiagram } from '../ParamDiagramContext';
import { useFunctionTree } from '../FunctionTreeContext';

const FunctionTree = ({ data, onSelectFunction, popperPlacement = 'right', selectMode = 'function' }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const { highlightedFunction } = useParamDiagram();
  const {user} = useFunctionTree();
  
  const convertToTreeItems = useCallback((node, parentId = 'root') => {
    let id, children = [], nodeType = '';
  
    if (node.main_function) {
      nodeType = 'main_function';
      id = `${parentId}-${node.main_function}`;
      children = node.sub_functions ? node.sub_functions.map(subFunc => convertToTreeItems(subFunc, id)) : [];
    } else if (node.sub_function) {
      nodeType = 'sub_function';
      id = `${parentId}-${node.sub_function}`;
      children = node.details ? node.details.map(detail => convertToTreeItems(detail, id)) : [];
    } else if (node.function_level0) {
      nodeType = 'function_level0';
      id = `${parentId}-${node.function_level0}`;
    }

    const highlighted = (highlightedFunction ?? []).includes(id.replace(/ /g, '_'));
  
    return {
      id: id.replace(/ /g, '_'),
      label: node.main_function || node.sub_function || node.function_level0,
      description: node.説明,
      children: children.length > 0 ? children : undefined,
      nodeType,
      highlighted,
      parentId,
    };
  },[highlightedFunction]);

  const treeItems = useMemo(() => {
    const rootItem = convertToTreeItems({
      id: 'root',
      main_function: data.product_name,
      sub_functions: data.function_tree
    });
    return [rootItem];
  }, [data, convertToTreeItems]);

  const getAllItemIds = useCallback((items) => {
    let ids = [];
    const collectIds = (item) => {
      ids.push(item.id);
      if (item.children) {
        item.children.forEach(collectIds);
      }
    };
    items.forEach(collectIds);
    return ids;
  }, []);

  const expandedItems = useMemo(() => getAllItemIds(treeItems), [treeItems, getAllItemIds]);

  const findItem = useCallback((items, id) => {
    for (let item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  }, []);

  const handleItemClick = (event, itemId) => {
    const clickedItem = findItem(treeItems, itemId);
    
    if (!clickedItem) {
      console.error(`Item with id ${itemId} not found`);
      return;
    }
  
    if (selectedItem && selectedItem.id === itemId && tooltipAnchorEl) {
      setSelectedItem(null);
      setTooltipAnchorEl(null);
      onSelectFunction && onSelectFunction(null);
    } else {
      setSelectedItem(clickedItem);
      setTooltipAnchorEl(event.currentTarget);

    const parentItem = findItem(treeItems, clickedItem.parentId);
    const parentName = parentItem ? parentItem.label : null;
    let grandParentName = null;

    if (clickedItem.nodeType === 'function_level0' && parentItem) {
      const grandParentItem = findItem(treeItems, parentItem.parentId);
      grandParentName = grandParentItem ? grandParentItem.label : null;
    }
  
      onSelectFunction && onSelectFunction({
        id: clickedItem.id,
        name: clickedItem.label,
        description: clickedItem.description,
        parentName: parentName,
        grandParentName: grandParentName,
        nodeType: clickedItem.nodeType,
      });
    }
  };

  return (
    <Box position="relative">
      <RichTreeView
        aria-label="Function tree"
        defaultExpandedItems={expandedItems}
        items={treeItems}
        onItemClick={handleItemClick}
      />
      {selectedItem && tooltipAnchorEl && (
      <Popper
        open={Boolean(tooltipAnchorEl)}
        anchorEl={tooltipAnchorEl}
        placement={popperPlacement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: popperPlacement === 'right-start' ? [30, -500] : [30, -250],
            },
          },
        ]}
      >
        <Paper sx={{ 
          p: 2, 
          width: popperPlacement === 'right-start' ? 450 : 270,
        }}>
          <Typography variant="body2">
            {selectedItem?.description}
          </Typography>
        </Paper>
      </Popper>
      )}
    </Box>
  );
};

export default FunctionTree;