import React from 'react';
import { Handle, Position } from 'reactflow';

const DetailFuncNodeWithCommonReq = ({ data, style }) => {
    const nodeStyle = {
        width: '150px',  // 固定幅を設定
        height: '30px',  // 固定高さを設定
        background: '#FFFFFF',
        fontSize: '12px',
        padding: '3px',
        border: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        ...style,  // 親コンポーネントから渡されたスタイルをマージ
    };

    const textStyle = {
        textAlign: 'center',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    };

    return (
        <div style={nodeStyle}>
            <div style={textStyle}>{data.label}</div>
            <Handle type="target" position={Position.Top} style={{ left: '50%' }} id="com" />
            <Handle type="target" position={Position.Left} style={{ top: '50%' }} id="ind" />
        </div>
    );
};

export default DetailFuncNodeWithCommonReq;