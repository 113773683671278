const ENV = process.env.REACT_APP_ENV || 'development';

const CONFIG = {
    development: {
      API_URL: 'http://localhost:8000',
    },
    test:{
      API_URL: 'http://localhost:8000',
    },
    production: {
      API_URL: 'https://gfas-app-522987519954.asia-northeast1.run.app',
    },
  };

// API base URL
export const API_URL = CONFIG[ENV].API_URL;

// API Endpoints
export const APIEndpoints = {
    FUNCTION_TREE : "/api/function-tree",
    FAILURE_MODES : "/api/failure-modes",
    SUB_FUNCTION_FAILURE_MODES : "/api/sub-function-failure-modes",
    IMPACT_ANALYSIS : "/api/impact-analysis",
    FMEA_RESULT : "/api/fmea-result",
    EXTRACT_FUNCTIONAL_REQUIREMENTS : "/api/functional-requirements/extract",
    EXTRACT_FUNCTIONAL_REQUIREMENTS_BATCH : "/api/functional-requirements/extract-batch",
    INTEGRATE_FUNCTIONAL_REQUIREMENTS : "/api/integrate-requirements",
    INTEGRATE_FUNCTIONAL_REQUIREMENTS_BATCH : "/api/integrate-requirements-batch",
    IMPLODE_FUNCTIONAL_REQUIREMENTS :"/api/subfunction-requirements/implode",
    IMPLODE_FUNCTIONAL_REQUIREMENTS_BATCH :"/api/subfunction-requirements/implode-batch",
    INTEGRATE_SUBFUNCTION_IMPLODED_REQUIREMENTS : "/api/integrate-subfunction-imploded-requirements",
    INTEGRATE_SUBFUNCTION_IMPLODED_REQUIREMENTS_BATCH : "/api/integrate-subfunction-imploded-requirements-batch",
    CONSOLIDATE_SUBFUNCTION_IMPLODED_REQUIREMENTS : "/api/consolidate-subfunction-imploded-requirements",
    COMMONIZE_REQUIREMENTS : "/api/commonize-requirements",
    PARAMETER_DIAGRAM : "/api/parameter-diagram",
    TOKEN_BALANCE: '/api/tokens/balance',
    TOKEN_USE: '/api/tokens/use',

};