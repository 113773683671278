import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Alert } from '@mui/material';
import { Download } from 'lucide-react';
import { useFunctionTree } from '../../FunctionTreeContext';

const DownloadFunctionTree = () => {
  const { authFetch } = useFunctionTree();
  const [isDownloading, setIsDownloading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleDownload = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    try {
      const response = await authFetch('/export/function-tree/html', {
        headers: {
          'Accept': 'text/html',
        },
        responseType: 'blob',  // このオプションを追加
      });

      // デバッグ用のログ
      console.log("Response headers:", [...response.headers.entries()]);
      console.log("Content-Disposition:", response.headers.get('content-disposition'));

      const blob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      let filename = '機能ツリー.html';  // デフォルトのファイル名

      if (contentDisposition) {
        // filename*=UTF-8''... 形式のマッチング
        const filenameMatch = /filename\*=UTF-8''([^;\s]+)/i.exec(contentDisposition);
        if (filenameMatch && filenameMatch[1]) {
          try {
            filename = decodeURIComponent(filenameMatch[1]);
          } catch (e) {
            console.error('Error decoding filename:', e);
          }
        }
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setAlertSeverity('success');
      setAlertMessage('ファイルのダウンロードが完了しました');
      setShowAlert(true);
    } catch (error) {
      console.error('Download error:', error);
      setAlertSeverity('error');
      setAlertMessage('ダウンロードに失敗しました。もう一度お試しください。');
      setShowAlert(true);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box className="inline-flex flex-col items-start">
      <Box className="flex items-center">
        <Tooltip title="HTML形式でダウンロード">
          <IconButton
            onClick={handleDownload}
            disabled={isDownloading}
            className={`ml-2 p-1 ${isDownloading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`}
            aria-label="機能ツリーをダウンロード"
          >
            <Download className="h-5 w-5" />
          </IconButton>
        </Tooltip>
      </Box>
      
      {showAlert && (
        <Alert
          className="mt-2"
          variant="outlined"
          severity={alertSeverity}
          onClose={() => setShowAlert(false)}
        >
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default DownloadFunctionTree;