import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>

      <Box sx={{ mb: 4 }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            トップページに戻る
          </Button>
        </Link>
      </Box>

      <Typography variant="h3" component="h1" gutterBottom>
        プライバシーポリシー
      </Typography>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          1. 基本方針
        </Typography>
        <Typography variant="body1">
          株式会社メディオクリタス（以下「当社」）は、提供するオンラインサービスGPT-Function Analysis Support System（以下「本サービス」）における個人情報の取扱いについて、個人情報保護法その他の関連法令を遵守し、ユーザーのプライバシー保護に最大限の注意を払います。本方針
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          2. 収集する個人情報
        </Typography>
        <Typography variant="body1">
          当社が本サービスを通じて収集する個人情報は以下の通りです：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>ユーザーが入力する情報
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>氏名</li>
              <li>メールアドレス</li>
              <li>その他ユーザーが本サービスに入力した情報</li>
            </ul>
          </li>
          <li>自動的に収集される情報
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>IPアドレス</li>
              <li>デバイス情報</li>
              <li>利用ログ</li>
              <li>Cookie情報</li>
            </ul>
          </li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          3. 個人情報の利用目的
        </Typography>
        <Typography variant="body1">
          収集した個人情報は、以下の目的で利用します：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>基本的な利用目的
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>本サービスの提供・運営</li>
              <li>ユーザーサポート</li>
              <li>システムの改善・最適化</li>
              <li>不正利用の防止</li>
            </ul>
          </li>
          <li>マーケティング活動
            <ul className="list-disc ml-6 mt-2 space-y-1">
              <li>当社からのコンサルティングサービスの提案</li>
              <li>製品・サービスに関する情報提供</li>
              <li>サービス向上のための分析</li>
            </ul>
          </li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          4. 個人情報の管理
        </Typography>
        <ol className="list-decimal ml-4 space-y-2">
          <li>当社は、収集した個人情報について、不正アクセス、紛失、破壊、改ざん、漏洩等を防止するため、適切な安全管理措置を講じます。</li>
          <li>個人情報の管理については、社内規程を設け、従業員教育を徹底し、適切な管理を行います。</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          5. 第三者提供の制限
        </Typography>
        <Typography variant="body1">
          当社は、以下の場合を除き、収集した個人情報を第三者に提供いたしません：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>ユーザーの同意がある場合</li>
          <li>法令に基づく場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合</li>
          <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合</li>
          <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          6. 業務委託
        </Typography>
        <Typography variant="body1">
          当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部または一部を委託する場合があります。その場合、当社は委託先との間で個人情報保護に関する契約を締結し、適切な管理・監督を行います。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          7. ビジネス提案活動について
        </Typography>
        <Typography variant="body1">
          当社は、本サービスを通じて得た情報をもとに、以下の活動を行う場合があります：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>コンサルティングサービスの提案</li>
          <li>関連するサービスや製品の案内</li>
          <li>サービス向上のためのヒアリング</li>
          <li>その他、ユーザーに有益と考えられる情報の提供</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          8. 開示請求等の手続き
        </Typography>
        <Typography variant="body1">
          ユーザーは、自己の個人情報について、開示、訂正、利用停止等を請求することができます。請求手続きの詳細については、お問い合わせ窓口までご連絡ください。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          9. お問い合わせ窓口
        </Typography>
        <Typography variant="body1">
          個人情報の取扱いに関するお問い合わせは、下記窓口までご連絡ください：
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            株式会社メディオクリタス
          </Typography>
          <Typography variant="body1">
            住所：
          </Typography>
          <Typography variant="body1">
            〒150-0012
          </Typography>
          <Typography variant="body1">
            東京都渋谷区広尾1丁目13番1号　フジキカイ広尾ビル５階
          </Typography>
          <Typography variant="body1">
            メール：service@g-fass.com
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" color="text.secondary">
          制定日：2024年10月25日
        </Typography>
        <Typography variant="body2" color="text.secondary">
          最終更新日：2024年10月25日
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            トップページに戻る
          </Button>
        </Link>
      </Box>

    </Container>
  );
}