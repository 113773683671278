import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParamDiagram } from '../../ParamDiagramContext';

const StyledTableCell = styled(TableCell)(({ theme, type, selected }) => ({
  ...(type === 'header' && {
    backgroundColor: '#34495E',
    border: '2px solid #34495E',
    color: theme.palette.common.white,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #FFA7CB',
    },
  }),
  ...(type === 'key' && {
    backgroundColor: '#BDC3C7',
    textAlign: 'center',
    fontWeight: 'bold',
  }),
  ...(type === 'commonkey' && {
    backgroundColor: '#EDEEEF',
    textAlign: 'center',
  }),
  ...(type === 'common' && {
      fontSize: '2em',
      fontWeight: 'bold',
      textAlign: 'center',
  }),
  ...(selected && {
    border: '2px solid #FF0066',
  }),
}));


const CommonizedRequirementsTable = ({ commonizationProcess, commonizedFunctionalRequirements, productName, mainFunctionName,subFunctionName }) => {
  const [tableData, setTableData] = useState({
    commonFunctions: [],
    childFunctions: [],
    individualFunctions: {},
    commonFunctionMap: {}
  });

  const {
    selectedPDiaTarget,
    setSelectedPDiaTarget,
    childFunctionDetails,
   } = useParamDiagram();

  useEffect(() => {
    const processData = () => {
      if (!commonizationProcess || !commonizedFunctionalRequirements) {
        console.error('Missing data for CommonizedRequirementsTable');
        return;
      }

      const commonFunctions = commonizationProcess.commonized_requirements?.map(item => item.commonized_requirement) || [];
      const childFunctions = Object.keys(commonizedFunctionalRequirements);
      const individualFunctions = {};
      const commonFunctionMap = {};

      childFunctions.forEach(childFunction => {
        if (commonizedFunctionalRequirements[childFunction]?.integrated_result) {
          individualFunctions[childFunction] = commonizedFunctionalRequirements[childFunction].integrated_result
            .filter(item => !item.is_commonized)
            .map(item => item.integrated_requirement);
        } else {
          individualFunctions[childFunction] = [];
        }
      });

      commonizationProcess.commonized_requirements?.forEach(item => {
        commonFunctionMap[item.commonized_requirement] = {};
        childFunctions.forEach(childFunction => {
          commonFunctionMap[item.commonized_requirement][childFunction] = 
          item.related_functionlevel0.includes(childFunction) ? '○' : '';
        });
      });

      setTableData({ commonFunctions, childFunctions, individualFunctions, commonFunctionMap });
    };

    processData();
  }, [commonizationProcess, commonizedFunctionalRequirements]);

  const handleHeaderClick = (index, childFunction) => {
    const isSelected = selectedPDiaTarget?.name === childFunction;
    const commonizedRequirements = commonizedFunctionalRequirements[childFunction]?.integrated_result
      // .filter(item => item.is_commonized)
      .map(item => item.integrated_requirement);

    setSelectedPDiaTarget(isSelected ? null : {
      product_name: productName,
      main_function: mainFunctionName,
      sub_function: subFunctionName,
      function_name: childFunction,
      description: childFunctionDetails[childFunction] || '',
      requirement: commonizedRequirements || [],
    });
  };


  if (!commonizationProcess || !commonizedFunctionalRequirements) {
    return <Typography>データが利用できません。</Typography>;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <TableContainer component={Paper} style={{ flexGrow: 1, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell type="key" colSpan={2}>詳細機能</StyledTableCell>
              {tableData.childFunctions.map((childFunction, index) => (
                <StyledTableCell 
                  type="header"  
                  key={childFunction}
                  onClick={() => handleHeaderClick(index, childFunction)}
                  selected={selectedPDiaTarget?.function_name === childFunction}
                >{childFunction}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell type="key" rowSpan={tableData.commonFunctions.length + 1}>共通要件</StyledTableCell>
            </TableRow>
            {tableData.commonFunctions.map((commonFunction, index) => (
              <TableRow key={index}>
                <StyledTableCell type="commonkey">{commonFunction}</StyledTableCell>
                {tableData.childFunctions.map(childFunction => (
                  <StyledTableCell type="common" key={`${childFunction}-${index}`}>
                    {tableData.commonFunctionMap[commonFunction]?.[childFunction] || ''}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <StyledTableCell type="key" colSpan={2} rowSpan={Math.max(...Object.values(tableData.individualFunctions).map(arr => arr.length)) + 1}>
                個別要件
              </StyledTableCell>
            </TableRow>
            {Array.from({ length: Math.max(...Object.values(tableData.individualFunctions).map(arr => arr.length)) }).map((_, index) => (
              <TableRow key={`individual-${index}`}>
                {tableData.childFunctions.map(childFunction => (
                  <TableCell key={`individual-${childFunction}-${index}`}>
                    {tableData.individualFunctions[childFunction]?.[index] || ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CommonizedRequirementsTable;