import React, { useState } from 'react';
import { auth } from './firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    
    try {
      if (isSignUp) {
        // 新規登録の場合
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        // メール認証の送信
        await sendEmailVerification(userCredential.user);
        setMessage('確認メールを送信しました。メールを確認して認証を完了してください。');
      } else {
        // ログインの場合
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
          // メール未認証の場合
          setError('メールアドレスが未認証です。確認メールから認証を完了してください。');
          await sendEmailVerification(userCredential.user);
          setMessage('確認メールを再送信しました。');
          // 未認証ユーザーはサインアウト
          await auth.signOut();
          return;
        }
        console.log("User signed in successfully");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('このメールアドレスは既に登録されています。');
          break;
        case 'auth/invalid-email':
          setError('無効なメールアドレスです。');
          break;
        case 'auth/operation-not-allowed':
          setError('メール/パスワードによる認証が無効になっています。');
          break;
        case 'auth/weak-password':
          setError('パスワードが脆弱です。より強力なパスワードを設定してください。');
          break;
        case 'auth/user-disabled':
          setError('このアカウントは無効化されています。');
          break;
        case 'auth/user-not-found':
          setError('アカウントが見つかりません。');
          break;
        case 'auth/wrong-password':
          setError('パスワードが間違っています。');
          break;
        default:
          setError('認証エラーが発生しました。もう一度お試しください。');
      }
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('パスワードリセットメールの送信には、メールアドレスを入力してください。');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('パスワードリセットメールを送信しました。');
    } catch (error) {
      setError('パスワードリセットメールの送信に失敗しました。');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
      
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="メールアドレス"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="パスワード"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {isSignUp ? '登録' : 'ログイン'}
      </Button>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="text"
          onClick={() => setIsSignUp(!isSignUp)}
        >
          {isSignUp ? 'すでにアカウントをお持ちの方はこちら' : 'アカウントをお持ちでない方はこちら'}
        </Button>
        <Button
          variant="text"
          onClick={handleForgotPassword}
        >
          パスワードをお忘れの方
        </Button>
      </Box>
    </Box>
  );
};

export default SignIn;