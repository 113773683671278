// src/utils/inputSanitizer.js

const MAX_PRODUCT_NAME_LENGTH = 100;
const MAX_DESIGN_CHANGE_TARGET_LENGTH = 200;
const MAX_DESIGN_CHANGE_DESCRIPTION_LENGTH = 1000;

const sanitizeInput = (input, maxLength) => {
  if (typeof input !== 'string') return '';
  return input
    .slice(0, maxLength)
    .replace(/[<>&'"=!?`~@#$%^*+\[\]{};:\\|,./]/g, '')
    .trim();
};

export const sanitizeProductName = (input) => {
  return sanitizeInput(input, MAX_PRODUCT_NAME_LENGTH);
};

export const validateFileType = (file) => {
  const allowedTypes = ['application/pdf'];
  return allowedTypes.includes(file.type);
};

export const sanitizeDesignChangeTarget = (input) => {
  return sanitizeInput(input, MAX_DESIGN_CHANGE_TARGET_LENGTH);
};

export const sanitizeDesignChangeDescription = (input) => {
  return sanitizeInput(input, MAX_DESIGN_CHANGE_DESCRIPTION_LENGTH);
};