import React, { useState } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Button, 
  Alert, 
  Tabs, 
  Tab,
  AppBar,
  Toolbar,
  Link as MuiLink
} from '@mui/material';
import { useFunctionTree } from '../../FunctionTreeContext';
import { useNavigate, Link } from 'react-router-dom';

const PricingPage = () => {
  const { user } = useFunctionTree();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const LegalInformation = () => (
    <Box sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        特定商取引法に基づく表記
      </Typography>
      <Box component="table" sx={{ width: '100%', borderCollapse: 'collapse' }}>
        <Box component="tbody">
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)', width: '30%' }}>
              事業者名
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              株式会社メディオクリタス
            </Box>
          </Box>
          {/* Rest of the legal information table rows */}
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              サービス代表者
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              荒川 康弘
            </Box>
          </Box>
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              所在地
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              〒150-0012 東京都渋谷区広尾1丁目13番1号　フジキカイ広尾ビル５階
            </Box>
          </Box>
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              連絡先
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              service@g-fass.com<br />
            </Box>
          </Box>
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              販売価格
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              料金プランに記載の通り
            </Box>
          </Box>
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              支払方法
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              クレジットカード決済
            </Box>
          </Box>
          <Box component="tr" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box component="th" sx={{ p: 2, textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
              キャンセル・解約
            </Box>
            <Box component="td" sx={{ p: 2 }}>
              サブスクリプションはいつでも解約が可能です。<br />
              アカウントはサブスクリプション解約後も有効であり、過去の利用結果については本サービス提供期間中に限り閲覧が可能です（この仕様は将来変更される場合があります）。<br />
              また、購読・購入いただいたトークンは、購読の更新日もしくは購入日から60日以内まで使用が可能です。<br />
              アカウントの削除をご希望の場合は、別途お問い合わせください。<br />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      {/* Header */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                fontFamily: 'Goldman, sans-serif',
                marginRight: 2
              }}
            >
              G-FASS
            </Typography>
            <img 
              src="/images/MC_newlogo_2.jpg" 
              alt="Mediocritas logo" 
              style={{ 
                height: '24px',
                width: 'auto',
                objectFit: 'contain'
              }} 
            />
          </Box>
          <Button color="inherit" onClick={() => navigate('/')}>ホーム</Button>
          <Button color="inherit" onClick={() => navigate('/features')}>機能紹介</Button>
          <Button 
            color="inherit" 
            href="https://www.mediocritas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </Button>
          <Button color="inherit" onClick={() => navigate('/contact')}>お問い合わせ</Button>
          <Button color="primary" variant="contained" onClick={() => navigate('/app')}>アプリを始める</Button>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <Container maxWidth="lg">
        {/* Pricing Tables (requires login) */}
        {!user ? (
          <Box sx={{ my: 4, textAlign: 'center' }}>
            <Alert severity="info" sx={{ mb: 2 }}>
              プランを選択する前にログインが必要です
            </Alert>
            <Button 
              variant="contained" 
              onClick={() => navigate('/app')}
              sx={{ mt: 2 }}
            >
              ログインする
            </Button>
          </Box>
        ) : (
          <Box sx={{ my: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom textAlign="center">
              料金プラン
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" textAlign="center" sx={{ mb: 4 }}>
              あなたのニーズに合わせたプランをお選びください。<br />
              ＜注意＞<br />
              既にサブスクリプションに申し込まれている方はStripeが提供する「プラン管理」への転送となります。<br />
              プラン変更時は変更時点でトークン付与され、次回の更新は１ヵ月後となります。<br />
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="月額プラン" />
                <Tab label="追加トークン購入" />
              </Tabs>
            </Box>

            <Box hidden={activeTab !== 0}>
              <stripe-pricing-table
                pricing-table-id="prctbl_1QEPnTRpQc8izEjXz3JUrjgf"
                publishable-key="pk_live_51QEHzkRpQc8izEjX6DguiRzJDJqBx6mZTv7mLXuVo3v00N1mg1DALDvmQlCbMGltC1DJcqGySe0TqVTA1wlCoIvY00gBr7IyQk"
                client-reference-id={user.uid}
              >
              </stripe-pricing-table>
            </Box>

            <Box hidden={activeTab !== 1}>
              <stripe-pricing-table
                pricing-table-id="prctbl_1QGszyRpQc8izEjX7FSPZPyT"
                publishable-key="pk_live_51QEHzkRpQc8izEjX6DguiRzJDJqBx6mZTv7mLXuVo3v00N1mg1DALDvmQlCbMGltC1DJcqGySe0TqVTA1wlCoIvY00gBr7IyQk"
                client-reference-id={user.uid}
              >
              </stripe-pricing-table>
            </Box>
          </Box>
        )}

        {/* Legal information (always visible) */}
        <LegalInformation />
      </Container>

      {/* Footer */}
      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3, mt: 8 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 2,
            mb: 2
          }}>
            <Link to="/terms" component={Button} color="inherit">
              利用規約
            </Link>
            <Link to="/privacy" component={Button} color="inherit">
              プライバシーポリシー
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © 2024 G-FASS. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default PricingPage;