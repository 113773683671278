import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// StyledTableCellの定義をここに移動
const StyledTableCell = styled(TableCell)(({ theme, column }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#DEDEDE',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  '&.MuiTableCell-body': {
    backgroundColor: column === 1 ? '#F3F3F3' : 'inherit',
    fontWeight: column === 2 ? 'bold' : 'inherit',
  },
  // 列ごとの幅を指定
  width: column === 0 ? '18.5%' : 
         column === 1 ? '10.5%' : 
         column === 2 ? '18.5%':
         '17.5%',
  // 残りの列（3, 4, 5）は均等に分配
  maxWidth: column === 0 ? '18.5%' : 
            column === 1 ? '10.5%' :
            column === 2 ? '18.5%': 
            '17.5',
}));

const FailureModeTable = ({ failureModes, functionName }) => {
  if (!failureModes || failureModes.length === 0) {
    return <Typography>この機能の故障モード分析結果がありません。</Typography>;
  }

  return (
    <TableContainer component={Paper} style={{ flexGrow: 1, overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell column={0}>故障影響</StyledTableCell>
            <StyledTableCell column={1}>モード分類</StyledTableCell>
            <StyledTableCell column={2}>故障モード</StyledTableCell>
            <StyledTableCell column={3}>故障原因-1</StyledTableCell>
            <StyledTableCell column={4}>故障原因-2</StyledTableCell>
            <StyledTableCell column={5}>故障原因-3</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {failureModes.map((mode, index) => (
            <TableRow key={index}>
              <StyledTableCell column={0}>{mode.effects && mode.effects[0] ? mode.effects[0] : '-'}</StyledTableCell>
              <StyledTableCell column={1}>{mode.mode || '-'}</StyledTableCell>
              <StyledTableCell column={2}>{mode.description || '-'}</StyledTableCell>
              <StyledTableCell column={3}>{mode.causes && mode.causes[0] ? mode.causes[0] : '-'}</StyledTableCell>
              <StyledTableCell column={4}>{mode.causes && mode.causes[1] ? mode.causes[1] : '-'}</StyledTableCell>
              <StyledTableCell column={5}>{mode.causes && mode.causes[2] ? mode.causes[2] : '-'}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FailureModeTable;