import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Box,
  Grid2,
} from '@mui/material';
import {
  AccountTree as TreeIcon,
  Warning as AlertIcon,
  Refresh as RefreshIcon,
  Hub as DiagramIcon, // パラメータダイアグラム用のアイコン
} from '@mui/icons-material';

export default function LandingPage() {
  const navigate = useNavigate();

  const features = [
    {
      icon: <TreeIcon sx={{ fontSize: 40 }} />,
      title: "機能ツリー",
      description: "製品の包括的な機能ツリーを生成"
    },
    {
      icon: <AlertIcon sx={{ fontSize: 40 }} />,
      title: "故障モード分析",
      description: "機能の故障モードとその故障影響・故障原因を生成"
    },
    {
      icon: <RefreshIcon sx={{ fontSize: 40 }} />,
      titleLines: ["設計変更", "影響分析"],
      description: "製品機能への影響を機能ツリーから包括的に抽出"
    },
    {
      icon: <DiagramIcon sx={{ fontSize: 40 }} />,
      titleLines: ["パラメータ", "ダイアグラム"],
      description: "機能のInput, Process, Output, Noise, Controlを生成"
    }
  ];

  return (
    <Box>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              fontFamily: 'Goldman, sans-serif',
              marginRight: 2  // ロゴとの間隔
            }}
          >
            G-FASS
          </Typography>
          <img 
            src="/images/MC_newlogo_2.jpg" 
            alt="Mediocritas logo" 
            style={{ 
              height: '24px',  // G-FASSの文字と同じ高さに調整
              width: 'auto',
              objectFit: 'contain'
            }} 
          />
        </Box>
          <Button color="inherit" onClick={() => navigate('/introduction')}>機能紹介</Button>
          <Button color="inherit" onClick={() => navigate('/pricing')}>料金プラン</Button>
          <Button 
            color="inherit" 
            href="https://www.mediocritas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </Button>
          <Button color="inherit" onClick={() => navigate('/contact')}>お問い合わせ</Button>
          <Button color="primary" variant="contained" onClick={() => navigate('/app')}>
            アプリを始める
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{ fontFamily: 'Goldman, sans-serif' }}  // Goldman フォントを適用
          >
            Welcome to G-FASS
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 2 }}>
            GPT-Function Analysis Support System
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" size="large" onClick={() => navigate('/app')} sx={{ mr: 2 }}>
              無料で始める
            </Button>
            <Button variant="outlined" size="large" onClick={() => navigate('/introduction')}>
              詳しく見る
            </Button>
          </Box>
        </Box>

        {/* Features Section */}
        <Box sx={{ my: 8 }} id="features">
          <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
            主な機能
          </Typography>
          <Grid2 
            container 
            spacing={4} 
            sx={{ 
              mt: 2,
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(4, 1fr)'
              },
              gap: 4
            }}
          >
            {features.map((feature, index) => (
              <Card 
                key={index}
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gridColumn: 'span 1',
                }}
              >
                <CardContent 
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    p: 3,
                  }}
                >
                  {/* アイコンセクション */}
                  <Box sx={{ mb: 2 }}>
                    {feature.icon}
                  </Box>
                  
                  {/* タイトルセクション */}
                  <Box 
                    sx={{ 
                      minHeight: '4em',  // タイトル部分の最小高さを確保
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      mb: 2
                    }}
                  >
                    {feature.titleLines ? (
                      // 2行タイトルの場合
                      feature.titleLines.map((line, i) => (
                        <Typography
                          key={i}
                          variant="h5"
                          component="h3"
                          sx={{
                            textAlign: 'center',
                            lineHeight: 1.2,
                            mb: i === 0 ? 1 : 0
                          }}
                        >
                          {line}
                        </Typography>
                      ))
                    ) : (
                      // 1行タイトルの場合
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{
                          textAlign: 'center',
                          lineHeight: 1.2
                        }}
                      >
                        {feature.title}
                      </Typography>
                    )}
                  </Box>

                  {/* 説明文セクション */}
                  <Box 
                    sx={{ 
                      mt: 'auto',  // 説明文を下部に配置
                      width: '100%' 
                    }}
                  >
                    <Typography 
                      color="text.secondary"
                      sx={{
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid2>
        </Box>
      </Container>

      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3, mt: 8 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 2,
            mb: 2
          }}>
            <Link to="/terms" component={Button} color="inherit">
              利用規約
            </Link>
            <Link to="/privacy" component={Button} color="inherit">
              プライバシーポリシー
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © 2024 G-FASS. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}