import React, { useState, useCallback, useEffect, useMemo, memo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Handle,
  Position,
  MarkerType,
  // MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, Typography, Button, Paper, Grid2, CircularProgress } from '@mui/material';
import { APIEndpoints } from '../../config';
import { useParamDiagram } from '../../ParamDiagramContext';
import { useFunctionTree } from '../../FunctionTreeContext';
import BidirectionalEdge from './BidirectionalEdge';
import PDiagramDownload from '../Download/PdiagramDownload';


// グループ内の子ノードのカスタムノードの定義
const CustomNode = memo(({ data, style }) => (
  <Paper elevation={3} style={{
    ...style,  // 既存のスタイルを展開
    padding: '10px',
    background: data.color,
    borderRadius: '5px',
    width: data.width || 180,
    height: data.height || 100,
    position: 'relative', // ハンドルの位置決めのために必要
  }}>
    <Handle
      type="target"
      position={Position.Left}
      style={{
        background: data.color, // ノードと同色
        width: 10,
        height: 10,
        border: 'none',
      }}
    />
    <Handle
      type="source"
      position={Position.Right}
      style={{
        background: data.color, // ノードと同色
        width: 10,
        height: 10,
        border: 'none',
      }}
    />
    <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>{data.label}</Typography>
    <Typography variant="body2">{data.description}</Typography>
  </Paper>
));

// グループノードのカスタムノードの定義
const GroupNode = ({ data, style }) => (
  <Paper elevation={3} style={{
    ...style,  // 既存のスタイルを展開
    padding: '10px',
    background: data.color || '#f0f0f0',
    borderRadius: '5px',
    width: data.width || 280,
    height: data.height || 250,
    position: 'relative',
  }}>
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
        position: 'absolute',
        width: '100%',  // 幅を100%に設定
        textAlign: 'center',  // テキストを中央揃えに
        ...(data.labelPosition === 'top' && { 
          top: '5px', 
          left: '50%',
          transform: 'translateX(-50%)'  // 水平方向に-50%移動
        }),
        ...(data.labelPosition === 'bottom' && { 
          bottom: '5px', 
          left: '50%',
          transform: 'translateX(-50%)'  // 水平方向に-50%移動
        }),
      }}
    >
      {data.label}
    </Typography>
  </Paper>
);

const nodeTypes = {
  custom: CustomNode,
  group: GroupNode,
};

const ParameterDiagram = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const { 
    selectedPDiaTarget,
    isPDiagramMode,
    handlePDiagramModeToggle
   } = useParamDiagram();
  const {user, authFetch, refreshTokenBalance} = useFunctionTree();

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const VERTICAL_SPACING = 50;
  const HORIZONTAL_SPACING = 50;
  const STEP_WIDTH = 270;
  const STEP_HEIGHT = 135;

  const calculateLayout = useMemo(() => (analysisResult) => {
    if (!analysisResult) return { nodes: [], edges: [] };
  
    const processSteps = analysisResult.structure.process;
    const processWidth = HORIZONTAL_SPACING + (STEP_WIDTH + HORIZONTAL_SPACING) * processSteps.length;
    const groupWidth = (processWidth - 2 * HORIZONTAL_SPACING) / 3;
    const processHeight = 200;
    const elementWidth = groupWidth - HORIZONTAL_SPACING *2;  
  
    const createGroupNode = (id, label, x, y, width, height, color, labelPosition = 'bottom') => ({
      id,
      type: 'group',
      position: { x, y },
      data: { label, width, height, color, labelPosition },
      style: {
        zIndex: -100, 
        background: 'transparent',  // 背景を透明に
        border: 'none',             // ボーダーを削除
        outline: 'none',            // アウトラインを削除
      },
      
    });
  
    const createChildNode = (id, parentId, label, description, x, y, color, width, height) => ({
      id,
      type: 'custom',
      position: { x, y },
      parentNode: parentId,
      data: { label, description, color, width, height },
      style: {
        zIndex: -50,
      }
    });
  
    let nodes = [];
    let edges = [];
  
    // Process group
    nodes.push(createGroupNode('Process', 'プロセス（P）', 0, 0, processWidth, processHeight, '#FFB6C1', 'top'));
    processSteps.forEach((step, index) => {
      nodes.push(createChildNode(
        `Process-${index}`,
        'Process',
        `Step ${index + 1}`,
        step.description,
        HORIZONTAL_SPACING + index * (STEP_WIDTH + HORIZONTAL_SPACING),
        50,
        '#FFEFF1',
        STEP_WIDTH,
        STEP_HEIGHT,
      ));
    });
  
    // Noise groups
    const noiseGroups = [
      { id: 'N-Var', label: 'ノイズ（N）個体差', data: analysisResult.noise_factors.Noise_piece_to_piece_Variation },
      { id: 'N-Time', label: 'ノイズ（N）経時変化', data: analysisResult.noise_factors.Noise_Change_over_time },
      { id: 'N-Usage', label: 'ノイズ（N）ユーザー使用', data: analysisResult.noise_factors.Noise_usage },
      { id: 'N-Ext', label: 'ノイズ（N）外部環境', data: analysisResult.noise_factors.Noise_external_environment },
    ];
  
    noiseGroups.forEach((group, index) => {
      const x = (index - 1) * (groupWidth + HORIZONTAL_SPACING);
      const height = group.data.length * 150 + VERTICAL_SPACING;
      nodes.push(createGroupNode(group.id, group.label, x, -height - VERTICAL_SPACING, groupWidth, height, '#FFA500', 'bottom'));
      group.data.forEach((item, itemIndex) => {
        nodes.push(createChildNode(
          `${group.id}-${itemIndex}`,
          group.id,
          item.factor,
          item.description,
          HORIZONTAL_SPACING,
          itemIndex * 150 + VERTICAL_SPACING,
          '#FFF0D5',
          elementWidth
        ));
      });
    });

  // N-System group
  const systemInteractions = analysisResult.system_interactions.system_interactions;
  const nSystemHeight = systemInteractions.length * 150 + VERTICAL_SPACING;
  nodes.push(createGroupNode('N-System', 'ノイズ（N）システム相互依存', 3 * (groupWidth + HORIZONTAL_SPACING), -nSystemHeight - VERTICAL_SPACING, groupWidth, nSystemHeight, '#FFA500', 'bottom'));
  systemInteractions.forEach((item, index) => {
    nodes.push(createChildNode(
      `N-System-${index}`,
      'N-System',
      item.interacting_function,
      item.impact_description,
      HORIZONTAL_SPACING,
      index * 150 + VERTICAL_SPACING,
      '#FFF0D5',
      elementWidth
    ));
  });

  // interdependencies からエッジを生成
  if (analysisResult.interdependencies && analysisResult.interdependencies.key_interdependencies) {
    analysisResult.interdependencies.key_interdependencies.forEach((interdependency, index) => {
      const [source, target] = interdependency.factors_involved;
      
      // ノードのIDを見つける関数
      const findNodeId = (label) => nodes.find(node => node.data.label === label)?.id;

      const sourceId = findNodeId(source);
      const targetId = findNodeId(target);

      if (sourceId && targetId) {
        edges.push({
          id: `edge-${index}`,
          source: sourceId,
          target: targetId,
          type: 'bidirectional',
          animated: true,
          style: { stroke: '#555' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          markerStart: {
            type: MarkerType.ArrowClosed,
            orient: 'auto-start-reverse',
          },
          // importanceに基づいて線の太さを設定
          strokeWidth: interdependency.importance === '高' ? 10 : 
                       interdependency.importance === '中' ? 5 : 2,
        });
      }
    });
  }

    
  // Input group
  const inputData = analysisResult.structure.input;
  const inputWidth = groupWidth;
  nodes.push(createGroupNode('Input', '入力（I）', -inputWidth - HORIZONTAL_SPACING, 0, inputWidth, processHeight, '#90EE90', 'top'));
  nodes.push(createChildNode(`Input-0`, 'Input', inputData.name, inputData.description, HORIZONTAL_SPACING, 50, '#DEFADE', elementWidth));

  // Output group
  const outputData = analysisResult.structure.output;
  const outputWidth = groupWidth;
  nodes.push(createGroupNode('Output', '出力（O）', processWidth + HORIZONTAL_SPACING, 0, outputWidth, processHeight, '#90EE90', 'top'));
  nodes.push(createChildNode(`Output-0`, 'Output', outputData.name, outputData.description, HORIZONTAL_SPACING, 50, '#DEFADE', elementWidth));

  // Bottom groups
  const function_definition = selectedPDiaTarget.description;
  const function_requirements = selectedPDiaTarget.requirement;  // これは配列
  const bottomGroups = [
    { id: 'Func-Def', label: '機能定義', data: [{ description: function_definition }], groupColor: '#C2A3FF', childColor: '#EBE1FF' },
    { id: 'Func-Req', label: '機能要件', data: function_requirements.map(req => ({ description: req })), groupColor: '#C2A3FF', childColor: '#EBE1FF' },
    { id: 'C-Control', label: 'コントロール（C）制約', data: analysisResult.environment_and_control.control_factor, groupColor: '#87CEEB', childColor: '#EAF7FC' },
    { id: 'C-State', label: 'コントロール（C）状態', data: analysisResult.environment_and_control.state_and_environment, groupColor: '#87CEEB', childColor: '#EAF7FC' },
  ];

  bottomGroups.forEach((group, index) => {
    const x = (index - 1) * (groupWidth + HORIZONTAL_SPACING);
    const elementHeight = group.id === 'Func-Req' ? STEP_HEIGHT / 2 : 100
    const height = group.data.length * (elementHeight+50) + VERTICAL_SPACING;
    nodes.push(createGroupNode(group.id, group.label, x, VERTICAL_SPACING + processHeight, groupWidth, height, group.groupColor, 'top'));
    group.data.forEach((item, itemIndex) => {
      let label = '';
      if (group.id === 'Func-Req') {
        label = `要件 ${itemIndex + 1}`;
      } else if (group.id === 'C-Control' || group.id === 'C-State') {
        label = item.factor;
      }
      nodes.push(createChildNode(
        `${group.id}-${itemIndex}`,
        group.id,
        label,
        item.description,
        HORIZONTAL_SPACING,
        itemIndex * (elementHeight + 50) + VERTICAL_SPACING,
        group.childColor,
        elementWidth,
        elementHeight,
      ));
    });
  });

    return { nodes, edges };
  }, [selectedPDiaTarget]);

  // 相互依存の双方向矢印の生成   
  const edgeTypes = {
    bidirectional: BidirectionalEdge,
  };
  
  //既存のパラメータダイアグラムの取得
  const fetchParameterDiagram = useCallback(async () => {
    try {
          const product_name= selectedPDiaTarget.product_name;
          const main_function_name= selectedPDiaTarget.main_function;
          const sub_function_name= selectedPDiaTarget.sub_function;
          const function_level0_name= selectedPDiaTarget.function_name;
      const data = await authFetch(`/api/parameter-diagram/${
        encodeURIComponent(product_name)}/${
        encodeURIComponent(main_function_name)}/${
        encodeURIComponent(sub_function_name)}/${
        encodeURIComponent(function_level0_name)}?user_id=${user.uid}`);
      setAnalysisResult(data);
    } catch (error) {
      console.error('Error fetching integrated requirements:', error);
      return [];
    }
  }, [selectedPDiaTarget, authFetch]);

  // 既存のデータがある場合は画面遷移時に内容を取得
  useEffect(() => {
    if (isPDiagramMode && selectedPDiaTarget) {
      fetchParameterDiagram();
    }
  }, [isPDiagramMode, selectedPDiaTarget, fetchParameterDiagram]);

  // 新規もしくは再生成
  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    try {
      const data = await authFetch(`${APIEndpoints.PARAMETER_DIAGRAM}?user_id=${user.uid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_name: selectedPDiaTarget.product_name,
          main_function_name: selectedPDiaTarget.main_function,
          sub_function_name: selectedPDiaTarget.sub_function,
          function_level0_name: selectedPDiaTarget.function_name,
        }),
      });
      await refreshTokenBalance();
      setAnalysisResult(data.result);
    } catch (error) {
      console.error('Error analyzing parameter diagram:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  // パラメータダイアグラムの結果を表示
  useEffect(() => {
    if (analysisResult) {
      const { nodes, edges } = calculateLayout(analysisResult);
      setNodes(nodes);
      setEdges(edges);
    }
  }, [analysisResult, calculateLayout, setNodes, setEdges]);

  return (
    <Box sx={{ height: '75vh', minWidth: '1200px' }}>
      <Grid2 container spacing={2} alignItems="center">
        <Grid2 item>
          <Typography variant="h5" gutterBottom>
            パラメータダイアグラム：{selectedPDiaTarget.function_name}
          </Typography>
        </Grid2>
        <Grid2 item>
          <Button
            variant="contained"
            onClick={handleAnalyze}
            disabled={isAnalyzing}
            sx={{ 
              mb: 2,
              bgcolor: '#28B263',
              '&:hover': { bgcolor: '#1D8148' },
              '&:disabled': {
                bgcolor: 'rgba(40, 178, 99, 0.5)',
              }
            }}
          >
            {isAnalyzing ? 'Analyzing...' : 'ANALYZE'}
          </Button>
        </Grid2>
        <Grid2 item>
          <PDiagramDownload
            productName={selectedPDiaTarget.product_name}
            mainFunctionName={selectedPDiaTarget.main_function}
            subFunctionName={selectedPDiaTarget.sub_function}
            functionName={selectedPDiaTarget.function_name}
            disabled={isAnalyzing || !analysisResult}
          />
        </Grid2>
        <Grid2 item>
          <Button
            variant="contained"
            onClick={handlePDiagramModeToggle}
            sx={{ mb: 2 }}
          >
            戻る
          </Button>
        </Grid2>
        <Grid2 item>
          {isAnalyzing && <CircularProgress />}
        </Grid2>
      </Grid2>    
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        minZoom={0.1}
        maxZoom={1.5}
        defaultZoom={0.5}
        nodesDraggable={false}
      >
        <Background />
        <Controls />
        {/* <MiniMap /> */}
      </ReactFlow>
    </Box>
  );
};

export default ParameterDiagram;