import React from 'react';
import { Container, Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import SignIn from '../../services/emailsignin';

export default function SignInPage() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* ヘッダー */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography 
              variant="h6" 
              component={Link}
              to="/"
              sx={{ 
                fontFamily: 'Goldman, sans-serif',
                marginRight: 2,
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              G-FASS
            </Typography>
            <img 
              src="/images/MC_newlogo_2.jpg" 
              alt="Mediocritas logo" 
              style={{ 
                height: '24px',
                width: 'auto',
                objectFit: 'contain'
              }} 
            />
          </Box>
          <Button color="inherit" onClick={() => navigate('/introduction')}>機能紹介</Button>
          <Button color="inherit" onClick={() => navigate('/pricing')}>料金プラン</Button>
          <Button 
            color="inherit" 
            href="https://www.mediocritas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </Button>
          <Button color="inherit" onClick={() => navigate('/contact')}>お問い合わせ</Button>
          <Button color="primary" variant="contained" onClick={() => navigate('/')}>ホームに戻る</Button>
        </Toolbar>
      </AppBar>

      {/* メインコンテンツ */}
      <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            サインイン
          </Typography>
          <SignIn />
        </Box>
      </Container>

      {/* フッター */}
      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3, mt: 8 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 2,
            mb: 2
          }}>
            <Link 
              to="/terms" 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit' 
              }}
            >
              利用規約
            </Link>
            <Link 
              to="/privacy" 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit' 
              }}
            >
              プライバシーポリシー
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © 2024 G-FASS. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}