import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import ReactFlow, { Background, Controls, ControlButton, useReactFlow, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { CommonReqGroupNode, IndReqGroupNode } from './CustomGroupNode';
import IndReqNode from './IndReqNode';
import DetailFuncNodeWithCommonReq from './DetailFuncWithCommonReq';
import { useFunctionTree } from '../../FunctionTreeContext';
import window_incterchange from '../../images/window_interchange.svg';

const nodeTypes = {
  commonReqGroup: CommonReqGroupNode,
  indReqGroup: IndReqGroupNode,
  indReq: IndReqNode,
  detFunc: DetailFuncNodeWithCommonReq,
};

const BottomPanelFlowInner = () => {
  const { 
    selectedNode, 
    integratedRequirements, 
    setIntegratedRequirements,
    consolidatedResult,
    setConsolidatedResult,
    commonizedRequiremnt,
    setCommonizedRequirement,
    mainFunctions, 
    subFunctionDetails,
    setImportanceContent,
    productName,
    isBottomPanelExpanded,
    setIsBottomPanelExpanded, 
    user,
    authFetch,
  } = useFunctionTree();
  

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [commonizedFunctionRequirement,setCommonizedFunctionRequirement,] = useState({});
  const [dataFetchComplete, setDataFetchComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFlowNode, setSelectedFlowNode] = useState(null);

  const replaceSpacesWithUnderscore = (str) => str.replace(/ /g, '_');

  // 詳細機能の集約した要件（各詳細機能の中の要件を集約）
  const fetchIntegratedRequirements = useCallback(async (productName, mainFunction, subFunction, detailFunction) => {
    try {
      const data = await authFetch(`/api/integrated-requirements/${
        encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(detailFunction))}`);
      return data.integrated_result;
    } catch (error) {
      console.error('Error fetching integrated requirements:', error);
      return [];
    }
  }, [authFetch]);

  // サブ機能の統合された要件
  const fetchConsolidatedUpperFunctions = useCallback(async (productName, mainFunction, subFunction) => {
    try {
      const data = await authFetch(`/api/consolidated-upper-functions/${
        encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}`);
      return data;
    } catch (error) {
      console.error('Error fetching consolidated upper functions:', error);
      return null;
    }
  }, [authFetch]);

  // 詳細機能の共通化した要件（詳細機能同士で共通化）
  const fetchCommonizedRequirements = useCallback(async (productName, mainFunction, subFunction) => {
    try {
      return await authFetch(`/api/commonized-requirements/${
        encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}`);
    } catch (error) {
      console.error('Error fetching commonized requirements:', error);
      return null;
    }
  }, [authFetch]);

  const fetchCommonizedFunctionRequirements = useCallback(async (productName, mainFunction, subFunction, detailFunction) => {
    try {
      return await authFetch(`/api/commonized-functional-requirements/${
        encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}/${
        encodeURIComponent(replaceSpacesWithUnderscore(detailFunction))}`);
    } catch (error) {
      console.error('Error fetching commonized requirements:', error);
      return null;
    }
  }, [authFetch]);
  
  const fetchAllData = useCallback(async () => {
      setIsLoading(true);
      if (selectedNode) {
        if (selectedNode.data.nodeType === 'detailFunction') {
          // Fetch data for detail function
          const { mainFunction, subFunction, label: detailFunction } = selectedNode.data;
          const requirements = await fetchIntegratedRequirements(productName, mainFunction, subFunction, detailFunction);
          setIntegratedRequirements(requirements);
        } else if (selectedNode.data.nodeType === 'subFunction') {
          // Fetch data for sub function
          const mainFunction = mainFunctions[selectedNode.data.label] || "Unknown Main Function";
          const subFunction = selectedNode.data.label;
          
          const upperFuncs = await fetchConsolidatedUpperFunctions(productName, mainFunction, subFunction);
          setConsolidatedResult(upperFuncs);
          
          const commonizedReqs = await fetchCommonizedRequirements(productName, mainFunction, subFunction);
          setCommonizedRequirement(commonizedReqs);
          
          // Fetch detail function data
          const childDetailFunctions = subFunctionDetails[subFunction] || [];
          const newDetailFunctionData = {};
          for (const detail of childDetailFunctions) {
            const commonizedFuncReqs = await fetchCommonizedFunctionRequirements(productName, mainFunction, subFunction, detail.label);
            newDetailFunctionData[detail.label] = commonizedFuncReqs;
          }
          setCommonizedFunctionRequirement(newDetailFunctionData);
        }
        setIsLoading(false);
      setDataFetchComplete(true);
    }
  }, [selectedNode, productName, mainFunctions, subFunctionDetails, fetchIntegratedRequirements, fetchConsolidatedUpperFunctions, fetchCommonizedRequirements, fetchCommonizedFunctionRequirements]);

  // 選択されたノードが変更された時のみデータをフェッチ
  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);


  // テキストの長さに基づいて幅を計算する関数
  const calculateNodeWidth = (text) => {
    const baseWidth = 200; // 基本の幅
    const charWidth = 10; // 1文字あたりのおおよその幅（ピクセル）
    const exceedingChar = Math.max(0, (text.length) - 39); //1行当たり13文字×3行
    const calculatedWidth = baseWidth + (Math.floor(exceedingChar/3))*charWidth; //3文字毎に幅を広げる
    return Math.min(Math.max(calculatedWidth, 200), 400); // 最小200px、最大400pxに制限
  };

const generateNodesAndEdges = useMemo(() => async () => {
  if (!isLoading && dataFetchComplete && selectedNode && selectedNode.data.label) {
    let newNodes = [];
    let newEdges = [];
    let finalEdges = [];  // 最終描画用の配列

        // 選択したノードが詳細機能の場合
        if (selectedNode.data.nodeType === 'detailFunction') {
          const { mainFunction, subFunction, label: detailFunction } = selectedNode.data;
          
          newNodes = [
            {
              id: 'root',
              type: 'input',
              data: { label: subFunction },
              position: { x: 50, y: 50 },
              style: { background: '#7070FF', color: '#fff', borderRadius: '5px' },
            },
            {
              id: 'selected',
              type: 'output',
              data: { label: selectedNode.data.label },
              position: { x: 50, y: 250 },
            },
          ];

          newEdges = [];

          if (integratedRequirements && Array.isArray(integratedRequirements.integrated_result)) {
            integratedRequirements.integrated_result.forEach((req, index) => {
              const reqNodeId = `req-${index}`;
              newNodes.push({
                id: reqNodeId,
                type: 'default',
                data: { 
                  label: req.integrated_requirement,
                  importance: req.importance
                },
                position: { x: 100 + index * 200, y: 150 },
                style: { background: '#C2A3FF', borderRadius: '5px', padding: '10px', fontSize: '12px', cursor: 'pointer' },
              });
              newEdges.push({ id: `e-sub-${reqNodeId}`, source: 'root', target: reqNodeId });
              newEdges.push({ id: `e-${reqNodeId}-detail`, source: reqNodeId, target: 'selected'});
            });
          }

        // 選択したノードがサブ機能の場合
        } else if (selectedNode.data.nodeType === 'subFunction') {
          const mainFunction = mainFunctions[selectedNode.data.label] || "Unknown Main Function";
          const subFunction = selectedNode.data.label;

          //サブ機能の上位のメイン機能と、選択したサブ機能の表示
          newNodes = [
            {
              id: 'main',
              type: 'input',
              data: { label: mainFunction },
              position: { x: 50, y: 50 },
              style: { background: '#9999FF', color: '#000', borderRadius: '5px' },
            },
            {
              id: 'sub',
              type: 'default',
              data: { label: selectedNode.data.label },
              position: { x: 50, y: 225 },
              style: { background: '#7070FF', color: '#fff', borderRadius: '5px' },
            },
          ];

          newEdges = [];
          
          // サブ機能の集約した要件Consolidatedが存在する場合
          if (consolidatedResult && Array.isArray(consolidatedResult.integrated_result)) {
            consolidatedResult.integrated_result.forEach((result, index) => {
              const resultNodeId = `result-${index}`;
              newNodes.push({
                id: resultNodeId,
                type: 'default',
                data: { 
                  label: result.consolidated_imploded_requirement,
                  importance: result.importance
                },
                position: { x: 100 + index * 200, y: 125 },
                style: { background: '#EBE0FF', borderRadius: '5px', padding: '10px', fontSize: '12px', cursor: 'pointer' },
              });
              newEdges.push({ id: `e-main-${resultNodeId}`, source: 'main', target: resultNodeId });
              newEdges.push({ id: `e-${resultNodeId}-sub`, source: resultNodeId, target: 'sub' });
            });
          }

          // サブ機能の子機能（詳細機能）を配置
          const childDetailFunctions = subFunctionDetails[subFunction] || [];
          const detailNodeIds = {};
          for (let index = 0; index < childDetailFunctions.length; index++) {
            const detail = childDetailFunctions[index];
            const detailNodeId = `detail-${index}`;

            detailNodeIds[detail.label] = detailNodeId;
            newNodes.push({
              id: detailNodeId,
              type: 'detFunc',
              data: { label: detail.label },
              position: { x: 325 + index * 400, y: 500 },
            });

            // 詳細機能の個別要件を配置
            // 共通化処理が済んでいるかどうかで表現を変更する
            const commonizedFuncReqs = await fetchCommonizedFunctionRequirements(productName, mainFunction, subFunction, detail.label);
            if (commonizedFuncReqs && commonizedFuncReqs.integrated_result) {

              // 共通化処理された要素をフィルタリングし、個別要件グループに収める要素数を抽出
              const individualReqs = commonizedFuncReqs.integrated_result.filter(req => !req.is_commonized);
              // 個別要件グループの高さを計算
              const groupHeight = Math.max(200, individualReqs.length * 60 + 50); // 最小高さ200px、各要件60px + 上下余白

              // 個別要件のグループを作成
              const groupNodeId = `ind-req-group-${index}`;
              newNodes.push({
                id: groupNodeId,
                type: 'indReqGroup',
                data: {
                   label: `個別要件`,
                   height: groupHeight 
                },
                position: { x: 100 + index * 400, y: 500 },
                style: {
                  width: 200,
                  height: groupHeight,
                },
              });
              
              // 個別要件のノードを作成
              individualReqs.forEach((req, reqIndex) => {
                const reqNodeId = `req-ind-${index}-${reqIndex}`;
                newNodes.push({
                  id: reqNodeId,
                  type: 'indReq',
                  data: { 
                    label: req.integrated_requirement,
                    importance: req.importance
                  },
                  position: { x: 25, y: 35 + reqIndex * 60 },
                  parentNode: groupNodeId,
                  extent: 'parent',
                });
                 // 個別要件から詳細機能へのエッジを追加
                newEdges.push({
                  id: `e-${reqNodeId}-${detailNodeId}`,
                  source: reqNodeId,
                  target: detailNodeId,
                  targetHandle: 'ind',
                  animated: false,
                  style: { stroke: '#888',  zIndex: 1000 },
                });    
              });
            } else {
              // 共通化処理がされていない場合は詳細機能の統合要件を読み込む
              const requirements = await fetchIntegratedRequirements(productName, mainFunction, subFunction, detail.label);
              requirements.forEach((req, reqIndex) => {
                const reqNodeId = `req-${index}-${reqIndex}`;
                newNodes.push({
                  id: reqNodeId,
                  type: 'default',
                  data: { 
                    label: req.integrated_requirement,
                    importance: req.importance
                  },
                  position: { x: 100 + index * 400, y: 575 + reqIndex * 90 }, 
                  style: { background: '#C2A3FF', borderRadius: 5, padding: '10px', fontSize: '12px', cursor: 'pointer' },
                });
              });
            }
          }

          // 共通化要件とエッジの生成
          if (commonizedRequiremnt && Array.isArray(commonizedRequiremnt.commonized_requirements)) {
            // 共通化要件ノードの配置とエッジの生成
            let nodeXPosition = 50;
            let maxHeight = 0;

            // まず、共通要件グループノードを作成
            const commonReqGroupNode = {
              id: `common-req-group`,
              type: 'commonReqGroup',
              data: {
                label: `共通要件`,
              },
              position: {x: 325, y: 325},
              zIndex:-100,
            };
            newNodes.push(commonReqGroupNode);

            commonizedRequiremnt.commonized_requirements.forEach((req, index) => {
              const reqNodeId = `req-common-${index}`;
              const labeltext = req.commonized_requirement;
              const nodeWidth = calculateNodeWidth(labeltext);
              const nodeHeight = 75; // ノードの高さ

              newNodes.push({
                id: reqNodeId,
                type: 'default',
                data: { 
                  label: req.commonized_requirement,
                  importance: req.importance
                },
                parentId: `common-req-group`,
                extent: 'parent',
                position: { x: nodeXPosition, y: 35 }, // ノード間の間隔を調整
                style: { 
                  background: '#EBE0FF',
                  borderRadius: '5px',
                  padding: '10px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  width: `${nodeWidth}px`,
                  height: `${nodeHeight}px`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal', // テキストを折り返す
                },
              }); 

              nodeXPosition += nodeWidth + 20;
              maxHeight = Math.max(maxHeight, nodeHeight + 35); // 35は上下のパディングの合計

              // 関連する詳細機能へのエッジを生成
              req.related_functionlevel0.forEach((relatedFunction) => {
                const targetNodeId = detailNodeIds[relatedFunction];
                if (targetNodeId) {
                  newEdges.push({
                    id: `e-${reqNodeId}-${targetNodeId}`,
                    source: reqNodeId,
                    target: targetNodeId,
                    targetHandle: 'com',
                    animated: false,
                    style: { stroke: '#888' },
                  }); 
                }
              });
            // 最後に、共通要件グループノードのサイズを更新
            commonReqGroupNode.style = {
              width: nodeXPosition + 30,
              height: maxHeight + 20,
            };
            commonReqGroupNode.data = {
              ...commonReqGroupNode.data,
              width: nodeXPosition + 20,
              height: maxHeight + 10,
            };
           });

            // サブ機能から共通化要件グループへのエッジ
            newEdges.push({ id: `e-sub-commonreqgroup`, source: 'sub', target: `common-req-group`, animated: false });
          }
        } //else if ===subfuntionの終わり

        // エッジを最前面に持ってくるための再描画
        newNodes.forEach(node => {
          if (node.type === 'indReq') {
            const [, , index, reqIndex] = node.id.split('-');
            const detailNodeId = `detail-${index}`;
            finalEdges.push({
              id: `e-${node.id}-${detailNodeId}`,
              source: node.id,
              target: detailNodeId,
              targetHandle: 'ind',
              animated: false,
              type: 'simplebezier',
              style: { stroke: '#888' },
              zIndex: 1000,
            });
          }
        });
        // 他の既存のエッジも finalEdges に追加
        newEdges.forEach(edge => {
          if (!finalEdges.some(e => e.id === edge.id)) {
            finalEdges.push({ ...edge, style: { ...edge.style}, zIndex: 1000  });
          }
        });
      // finalEdges を使用して結果を返す
      return { nodes: newNodes, edges: finalEdges };
    }
    return { nodes: [], edges: [] };
  }, [isLoading, dataFetchComplete, selectedNode, mainFunctions, productName, integratedRequirements, consolidatedResult, commonizedRequiremnt, subFunctionDetails, fetchIntegratedRequirements, fetchCommonizedFunctionRequirements]);

    // ノードとエッジの更新
    useEffect(() => {
      const updateNodesAndEdges = async () => {
        if (!isLoading && dataFetchComplete) {
          const { nodes, edges } = await generateNodesAndEdges();
          setNodes(nodes);
          setEdges(edges);
        }
      };
      updateNodesAndEdges();
    }, [generateNodesAndEdges, isLoading, dataFetchComplete]);

    const onNodeClick = useCallback((event, node) => {
      setSelectedFlowNode(prevNode => {
        const newSelectedNode = prevNode && prevNode.id === node.id ? null : node;
        
        // ノードのスタイルを更新
        setNodes(nds => nds.map(n => ({
          ...n,
          style: {
            ...n.style,
            border: n.id === node.id && newSelectedNode ? '2px solid red' : 'none',
          },
        })));
  
        // 選択解除時にimportanceContentをクリア
        if (!newSelectedNode) {
          setImportanceContent(null);
        } else if (node.data.importance) {
          setImportanceContent(node.data.importance);
        }
  
        return newSelectedNode;
      });
    }, [setNodes, setImportanceContent]);

  const { fitView } = useReactFlow();
  const reactFlowInstance = useRef(null);

  useEffect(() => {
    if (reactFlowInstance.current) {
      setTimeout(() => {
        reactFlowInstance.current.fitView();
      }, 0);
    }
  }, [isBottomPanelExpanded]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodeClick={onNodeClick}
      nodeTypes={nodeTypes}
      fitView
      nodesDraggable={false}
      minZoom={0.1}
      maxZoom={1.5}
      onInit={(instance) => {
        reactFlowInstance.current = instance;
      }}
    >
      <Background />
      <Controls>
        <ControlButton onClick={() => {
          setIsBottomPanelExpanded(!isBottomPanelExpanded);
        }}>
          <img src={window_incterchange} alt="replace window size" style={{ width: '24px', height: '24px' }} />
        </ControlButton>
      </Controls>
    </ReactFlow>
  </div>
);
};

const BottomPanelFlow = () => (
  <ReactFlowProvider>
    <div style={{ height: '100%', width: '100%' }}>
      <BottomPanelFlowInner />
    </div>
  </ReactFlowProvider>
);

export default BottomPanelFlow;

