import React from 'react';
import { Handle, Position } from 'reactflow';

const SubFunctionNode = ({ data, style }) => {
    const nodeStyle = {
        minWidth: '130px',  // 固定幅を設定
        minHeight: '30px',  // 固定高さを設定
        fontSize:'0.75rem',
        padding: '3px',
        border: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,  // 親コンポーネントから渡されたスタイルをマージ
      };
    
    return (
        <div style={nodeStyle}>
            <Handle type="target" position={Position.Left} style={{ top: '50%' }} />
            <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>{data.label}</div>
            <Handle type="source" position={Position.Bottom} style={{ left: '50%' }} />
        </div>
    );
};

export default SubFunctionNode;