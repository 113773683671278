import React, { useState, useEffect } from 'react';
import { RefreshCw } from 'lucide-react';
import { APIEndpoints } from '../../config'
import { useFunctionTree } from '../../FunctionTreeContext';
import {  Grid2 } from '@mui/material';

const TokenBalance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {user, tokenBalance, refreshTokenBalance } = useFunctionTree();

  const fetchBalance = async () => {
    setIsLoading(true);
    setError(null);
    try {
      refreshTokenBalance();
    } catch (err) {
      setError('Failed to load token balance');
      console.error('Error fetching token balance:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, [user, refreshTokenBalance]);

  return (
    <Grid2 container alignItems="center" spacing={1} sx={{ display: 'inline-flex' }}>
      <Grid2 item>
        {error ? '--' : isLoading ? '...' : `${tokenBalance || 0} tokens`}
      </Grid2>
      <Grid2 item sx={{ ml: 1 }}>
        <button
          onClick={fetchBalance}
          disabled={isLoading}
          aria-label="Refresh token balance"
          style={{
            cursor: 'pointer',
            padding: '4px',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            transition: 'background-color 0.2s ease',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f5f5f5'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
        >
          <RefreshCw 
            className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`}
            style={{ color: '#666' }}  // アイコンの色をグレーに
          />
        </button>
      </Grid2>
    </Grid2>
  );
};

export default TokenBalance;