import React from 'react';
import { Box, Container, Typography, AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

const ContactPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      {/* Header */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                fontFamily: 'Goldman, sans-serif',
                marginRight: 2
              }}
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            >
              G-FASS
            </Typography>
            <img 
              src="/images/MC_newlogo_2.jpg" 
              alt="Mediocritas logo" 
              style={{ 
                height: '24px',
                width: 'auto',
                objectFit: 'contain'
              }} 
            />
          </Box>
          <Button color="inherit" onClick={() => navigate('/introduction')}>機能紹介</Button>
          <Button color="inherit" onClick={() => navigate('/pricing')}>料金プラン</Button>
          <Button 
            color="inherit" 
            href="https://www.mediocritas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </Button>
          <Button color="inherit" onClick={() => navigate('/contact')}>お問い合わせ</Button>
          <Button color="primary" variant="contained" onClick={() => navigate('/app')}>
            アプリを始める
          </Button>
        </Toolbar>
      </AppBar>

      {/* Contact Form Section */}
      <Container maxWidth="md">
        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ mb: 4, fontFamily: 'Goldman, sans-serif' }}
          >
            お問い合わせ
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: 6, maxWidth: '600px', mx: 'auto' }}
          >
            G-FASSに関するご質問・ご相談はこちらのフォームからお願いいたします。<br />
            通常2営業日以内にご返信させていただきます。
          </Typography>
          
          <Box
            sx={{
              width: '100%',
              height: '800px',
              border: 'none',
              overflow: 'hidden',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfSX6lYglsfRMEE-FWjDMUtMrIwe8Jj4yagakImErN59CctXA/viewform?usp=pp_url"
              width="100%"
              height="100%"
              style={{ border: 'none', margin: 0 }}
              title="お問い合わせフォーム"
            >
              読み込んでいます...
            </iframe>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3, mt: 8 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 2,
            mb: 2
          }}>
            <Link to="/terms" component={Button} color="inherit">
              利用規約
            </Link>
            <Link to="/privacy" component={Button} color="inherit">
              プライバシーポリシー
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            © 2024 G-FASS. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactPage;