import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function TermsOfService() {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>

      <Box sx={{ mb: 4 }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            トップページに戻る
          </Button>
        </Link>
      </Box>

      <Typography variant="h3" component="h1" gutterBottom>
        利用規約
      </Typography>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          1. はじめに
        </Typography>
        <Typography variant="body1">
          本規約は、株式会社メディオクリタス（以下「当社」といいます。）の提供するオンラインサービスGPT-Function Analysis Support System（以下「本サービス」）の提供条件および当社と契約者との間の権利関係が定められています。本サービスのご利用に際しては本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。なお、契約者が本サービスの利用を開始した時点をもって本規約に同意をしたものとみなします。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          2. サービスの内容
        </Typography>
        <Typography variant="body1">
          本サービスは、AI技術を用いて製品の機能分析結果を提供するものです。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          3. 知的財産権
        </Typography>
        <ol className="list-decimal ml-4 space-y-2">
          <li>本サービスに関する一切の知的財産権は、当社に帰属します。</li>
          <li>ユーザーは、本サービスのリバースエンジニアリング、デコンパイル、逆アセンブル等の解析行為を行うことはできません。</li>
          <li>ユーザーが本サービスを通じて生成したコンテンツの知的財産権は、適用される法令に従って取り扱われます。</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          4. 禁止事項
        </Typography>
        <Typography variant="body1">
          以下の行為を禁止します：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>DDoS攻撃、プロンプトインジェクション等の不正アクセス</li>
          <li>本サービスの正常な運営を妨害する行為</li>
          <li>AIシステムの誤作動を意図的に引き起こす行為</li>
          <li>他のユーザーまたは第三者に害を及ぼす行為</li>
          <li>法令違反または公序良俗に反する行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          5. 免責事項
        </Typography>
        <ol className="list-decimal ml-4 space-y-2">
          <li>当社は、本サービスを通じて生成された結果の正確性、完全性、有用性、適時性等について、いかなる保証も行いません。</li>
          <li>ユーザーは、本サービスの利用およびその結果の使用について、自己の責任において行うものとし、当社は一切の責任を負いません。</li>
          <li>生成結果に起因して発生した損害、不利益、またはトラブルについて、当社は一切の責任を負わないものとします。</li>
          <li>本サービスの停止、中断、変更、終了等により生じたいかなる損害についても、当社は責任を負いません。</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          6. 利用制限
        </Typography>
        <Typography variant="body1">
          当社は、以下の場合にユーザーの本サービスの利用を制限または停止することができます：
        </Typography>
        <ol className="list-decimal ml-4 space-y-2 mt-2">
          <li>本規約に違反した場合</li>
          <li>不正アクセスまたは不正利用が確認された場合</li>
          <li>その他、当社が必要と判断した場合</li>
        </ol>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          7. サービスの変更・終了
        </Typography>
        <Typography variant="body1">
          当社は、予告なく本サービスの内容を変更、または終了することができます。これによりユーザーに損害が生じた場合でも、当社は一切の責任を負いません。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          8. 準拠法と管轄裁判所
        </Typography>
        <Typography variant="body1">
          本規約の解釈および適用は日本法に準拠するものとし、本サービスに関する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          9. 規約の変更
        </Typography>
        <Typography variant="body1">
          当社は、必要に応じて本規約を変更することができます。変更後の規約は、本サービス上で告知した時点から効力を生じるものとします。
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" color="text.secondary">
          制定日：2024年10月25日
        </Typography>
        <Typography variant="body2" color="text.secondary">
          最終更新日：2024年10月25日
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            トップページに戻る
          </Button>
        </Link>
      </Box>


    </Container>
  );
}