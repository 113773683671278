import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from './services/firebase';
import { API_URL, APIEndpoints } from './config';

// Context オブジェクトを作成
const FunctionTreeContext = createContext();

// Provider コンポーネントを定義
export const FunctionTreeProvider = ({ children }) => {
  // 共有したい状態を useState で定義
  const [selectedNode, setSelectedNode] = useState(null);
  const [productName, setProductName] = useState('');
  const [mainFunctions, setMainFunctions] = useState({});
  const [integratedRequirements, setIntegratedRequirements] = useState(null);
  const [consolidatedResult, setConsolidatedResult] = useState(null);
  const [commonizedRequiremnt, setCommonizedRequirement] = useState(null);
  const [subFunctionDetails, setSubFunctionDetails] = useState({});
  const [importanceContent, setImportanceContent] = useState(null);
  const [isBottomPanelExpanded, setIsBottomPanelExpanded] = useState(false);
  const [selectedDetailedFunction, setSelectedDetailedFunction] = useState(null); 
  const [comReqTableData, setComReqTableData] = useState({commonFunctions: [], childFunctions: [], individualFunctions: {}});
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenBalance, setTokenBalance] = useState(null);
 
  // ユーザー状態を取得
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // 認証済みのfetch関数（カプセル化）
  const authFetch = useCallback(async (endpoint, options = {}) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user);
    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };

    const response = await fetch(`${API_URL}${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

  // レスポンスタイプに応じて処理を分岐
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }
  
  if (options.responseType === 'blob' || 
    (options.headers && options.headers['Accept'] === 'text/html')) {
    return response;
  }

}, [user]);


  // トークン残高を更新する関数
  const refreshTokenBalance = useCallback(async () => {
    if (!user) return;
    
    try {
      const response = await authFetch(`${APIEndpoints.TOKEN_BALANCE}`);
      setTokenBalance(response.total);
      return response.total;
    } catch (error) {
      console.error('Error fetching token balance:', error);
      return null;
    }
  }, [user]);


  // Provider コンポーネントを返す
  return (
    <FunctionTreeContext.Provider value={{
      selectedNode,
      setSelectedNode,
      productName,
      setProductName,
      mainFunctions,
      setMainFunctions,
      integratedRequirements,
      setIntegratedRequirements,
      consolidatedResult,
      setConsolidatedResult,
      commonizedRequiremnt,
      setCommonizedRequirement,
      subFunctionDetails,
      setSubFunctionDetails,
      importanceContent,
      setImportanceContent,
      isBottomPanelExpanded,
      setIsBottomPanelExpanded,
      selectedDetailedFunction,
      setSelectedDetailedFunction,
      comReqTableData,
      setComReqTableData,
      user,
      setUser,
      loading,
      authFetch,
      tokenBalance,
      refreshTokenBalance,
    }}>
      {children}
    </FunctionTreeContext.Provider>
  );
};

// カスタムフックを作成して Context の使用を簡略化
export const useFunctionTree = () => {
  const context = useContext(FunctionTreeContext);
  if (context === undefined) {
    throw new Error('useFunctionTree must be used within a FunctionTreeProvider');
  }
  return context;
};