// TokenIntroduction.jsx
import { Timer, User, Plus } from 'lucide-react'
import './TokenIntroduction.css'

export default function Component() {
  const tokenConsumption = [
    {
      title: "機能ツリーの生成",
      tokens: 1,
      description: "製品仕様書から機能ツリーを自動生成",
    },
    {
      title: "設計変更影響抽出",
      tokens: 12,
      description: "設計変更が与える影響範囲を分析",
    },
    {
      title: "単故障モード分析",
      tokens: 6,
      description: "各機能の潜在的な故障モードを分析",
    },
    {
      title: "グループ故障モード分析",
      tokens: 24,
      description: "複数機能の連携による故障モードを分析",
    },
    {
      title: "機能の要件抽出",
      tokens: 50,
      description: "各機能の詳細要件を抽出",
    },
    {
      title: "パラメータダイヤグラム生成",
      tokens: 80,
      description: "機能のパラメータ関係を整理",
    },
  ]

  const tokenPlans = [
    {
      title: "無料トライアル",
      tokens: 20,
      description: "アカウント作成時に付与",
      icon: <User className="icon blue" />,
    },
    {
      title: "簡易ユーザー",
      tokens: 20,
      description: "月額プラン",
      icon: <User className="icon green" />,
    },
    {
      title: "軽量ユーザー",
      tokens: 240,
      description: "月額プラン",
      icon: <User className="icon orange" />,
    },
    {
      title: "標準ユーザー",
      tokens: 3300,
      description: "月額プラン",
      icon: <User className="icon red" />,
    },
    {
      title: "追加トークン",
      tokens: 100,
      description: "必要に応じて購入可能",
      icon: <Plus className="icon purple" />,
    },
  ]

  return (
    <div className="container">
      {/* Token System Overview */}
      <div className="overview-card">
        <div className="overview-header">
          <Timer className="icon blue" />
          <h2 className="title">トークンシステムの特徴</h2>
        </div>
        <h3 className="subtitle">トークンの有効期限: 60日</h3>
        <p className="description">
          購入したトークンは60日間有効です。期限内に使用されなかったトークンは失効します。
        </p>
      </div>

      {/* Token Consumption */}
      <div className="section">
        <h2 className="section-title">機能別トークン消費量</h2>
        <div className="consumption-grid">
          {tokenConsumption.map((item, index) => (
            <div key={index} className="card">
              <h3 className="card-title">{item.title}</h3>
              <p className="token-count">{item.tokens}トークン</p>
              <p className="card-description">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Token Plans */}
      <div className="section">
        <h2 className="section-title">トークンプラン</h2>
        <div className="plans-grid">
          {tokenPlans.map((plan, index) => (
            <div key={index} className="card">
              <div className="plan-header">
                {plan.icon}
                <h3 className="plan-title">{plan.title}</h3>
              </div>
              <p className="token-count">{plan.tokens}トークン</p>
              <p className="card-description">{plan.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}