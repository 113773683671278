import React, { useState, useCallback } from 'react';
import { Grid2, Paper, Typography, Box, CircularProgress, Button, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { APIEndpoints } from '../../config';
import FunctionTree from '../FunctionTree';
import FailureModeTable from './FailureModeTable';
import { useFunctionTree } from '../../FunctionTreeContext';

const FunctionButton = styled(Button)({
  backgroundColor: '#C2C2FF',
  color: 'black',
  '&:hover': {
    backgroundColor: '#A0A0FF',
  },
  margin: '0 5px 5px 0',
});

const GroupFailureModeAnalysis = ({ functionTreeData, productName }) => {
  const [selectedSubFunction, setSelectedSubFunction] = useState(null);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [selectedFunctionData, setSelectedFunctionData] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [functionData, setFunctionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {user, authFetch, refreshTokenBalance} = useFunctionTree();

  const handleSelectSubFunction = (func) => {
    setSelectedSubFunction({
      ...func,
      productName: productName
    });
    setSelectedFunction(null);
    setFunctionData(null);
  };

  const handleAnalyze = async () => {
    if (!selectedSubFunction || !user) return;
  
    setLoading(true);
    setError(null);
  
    try {
      const result = await authFetch(APIEndpoints.SUB_FUNCTION_FAILURE_MODES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_name: selectedSubFunction.productName,
          main_function_name: selectedSubFunction.parentName,
          sub_function_name: selectedSubFunction.name
        }),
      });

      await refreshTokenBalance();
      setAnalysisResults(result);
      if (result.analyzed_functions && result.analyzed_functions.length > 0) {
        const firstFunction = result.analyzed_functions[0];
        setSelectedFunction(firstFunction.function_name);
        setSelectedFunctionData(firstFunction.result[firstFunction.function_name]);
      }
    } catch (error) {
      console.error('Error analyzing sub-function failure modes:', error);
      setError('Failed to analyze sub-function failure modes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFunctionSelect = useCallback((functionName) => {
    setSelectedFunction(functionName);
    if (analysisResults && analysisResults.analyzed_functions) {
      const selectedFunctionData = analysisResults.analyzed_functions.find(
        func => func.function_name === functionName
      );
      if (selectedFunctionData && selectedFunctionData.result) {
        setSelectedFunctionData(selectedFunctionData.result[functionName]);
      } else {
        setSelectedFunctionData(null);
      }
    }
  }, [analysisResults]);

  const renderAnalysisResults = () => {
    if (!selectedSubFunction) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography>
            分析結果がありません。<br />
            サブ機能を選択して分析を実行してください。
          </Typography>
        </Box>
      );
    }

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (!analysisResults) {
      return (
        <Box alignItems="left">
          <Typography gutterBottom>選択されたサブ機能の分析結果がありません。</Typography>
        </Box>
      );
    }
    
    return (
      <Box height="100%" display="flex" flexDirection="column">
            <Typography variant="h6" gutterBottom>
              サブ機能: {selectedSubFunction.name}
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {analysisResults.analyzed_functions && analysisResults.analyzed_functions.length > 0 ? (
          <Box>
            <Typography variant="h6" gutterBottom>
              分析された機能: {selectedFunction}
            </Typography>
            <Box display="flex" flexWrap="wrap">
              {analysisResults.analyzed_functions.map((func, index) => (
                <FunctionButton
                  key={index}
                  onClick={() => handleFunctionSelect(func.function_name)}
                  disabled={loading}
                >
                  {func.function_name}
                </FunctionButton>
              ))}
            </Box>
            {selectedFunction && selectedFunctionData && (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                  機能: {selectedFunction}
                </Typography>
                <FailureModeTable 
                  failureModes={selectedFunctionData.failure_modes} 
                  functionName={selectedFunction}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Typography>故障モードの分析結果がありません。</Typography>
        )}
      </Box>
    );
  };

  return (
    <Grid2 container spacing={2} sx={{ width: '100%' }}>
      <Grid2 item size={{ xs:12, md:6, lg:4}}>
        <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
          <Typography variant="h6">機能ツリー</Typography>
          <FunctionTree 
            data={functionTreeData} 
            onSelectFunction={handleSelectSubFunction} 
            popperPlacement="left-start"
            selectMode="subFunction"
          />
        </Paper>
      </Grid2>
      <Grid2 item size={{ xs:12, md:6, lg:8}}>
        <Paper style={{ height: '75vh', overflow: 'auto', padding: '0.5rem'}}>
          <Grid2 container alignItems="center" spacing={2}>
            <Grid2 item xs>
              <Typography variant="h6">グループ故障モード分析結果</Typography>
            </Grid2>
            <Grid2 item>
              <Button 
                variant="contained" 
                onClick={handleAnalyze} 
                disabled={loading || !selectedSubFunction}
                sx={{ mb: 2, alignSelf: 'flex-start' }}
              >
                {loading ? 'Analyzing...' : 'Analyze'}
              </Button>
            </Grid2>
          </Grid2>
          {renderAnalysisResults()}
        </Paper>
      </Grid2>
    </Grid2>
  );
};

export default GroupFailureModeAnalysis;