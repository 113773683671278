import React, { useState, useEffect } from 'react';
import { Grid2, Paper, Typography, Box, CircularProgress, Button, Alert } from '@mui/material';
import { APIEndpoints } from '../../config';
import FunctionTree from '../FunctionTree';
import FailureModeTable from './FailureModeTable';
import { useFunctionTree } from '../../FunctionTreeContext';
import SingleFailureModeDownload from '../Download/SingleFailureModeDownload'

const SingleFailureModeAnalysis = ({ functionTreeData, productName }) => {
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {user, authFetch, refreshTokenBalance} = useFunctionTree();

  const handleSelectFunction = (func) => {
    setSelectedFunction({
      ...func,
      productName: productName
    });
  };

  useEffect(() => {
    if (selectedFunction) {
      fetchExistingAnalysis();
    }
  }, [selectedFunction]);


  // 既存の故障モード分析結果の取得
  const fetchExistingAnalysis = async () => {
    if (!selectedFunction || !user) return; 
    setLoading(true);
    setError(null);

    try {
      const encodedProductName = encodeURIComponent(selectedFunction.productName);
      const encodedMainFunction = encodeURIComponent(selectedFunction.grandParentName);
      const encodedSubFunction = encodeURIComponent(selectedFunction.parentName);
      const encodedFunctionName = encodeURIComponent(selectedFunction.name);
  
      const endpoint = `${APIEndpoints.FMEA_RESULT}/${encodedProductName}/${encodedMainFunction}/${encodedSubFunction}/${encodedFunctionName}`;
  
      const data = await authFetch(endpoint);
      setAnalysisResults(data);
    } catch (error) {
      console.error('Error fetching existing analysis:', error);
      if (error.message.includes('404')) {
        setAnalysisResults(null);
      } else {
        setError(`Failed to fetch existing analysis: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // 単機能の故障モード分析
  const handleAnalyze = async () => {
    if (!selectedFunction || !user) return;
    setLoading(true);
    setError(null);

    try {  
      const result = await authFetch(`${APIEndpoints.FAILURE_MODES}?user_id=${user.uid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_name: selectedFunction.productName,
          main_function_name: selectedFunction.grandParentName,
          sub_function_name: selectedFunction.parentName,
          function_level0_name: selectedFunction.name
        }),
      });
      await refreshTokenBalance();
      setAnalysisResults(result);
    } catch (error) {
      console.error('Error analyzing failure modes:', error);
      setError('Failed to analyze failure modes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderAnalysisResults = () => {
    if (!selectedFunction) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography>
            分析結果がありません。<br />
            機能を選択して分析を実行してください。
          </Typography>
        </Box>
      );
    }
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }
    
    const functionData = analysisResults ? analysisResults[selectedFunction.name] : null;
    
    if (!functionData) {
      return (
        <Box   alignItems="left">
          <Typography gutterBottom>選択された機能の分析結果がありません。</Typography>
        </Box>
      );
    }

    const { description, upper_function, failure_modes } = functionData;
    
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <Typography variant="h6" gutterBottom>
          機能: {selectedFunction.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          説明: {description || '説明なし'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          上位機能: {upper_function || '上位機能なし'}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <FailureModeTable 
          failureModes={failure_modes} 
          functionName={selectedFunction.name}
        />
      </Box>
    );
  };

  return (
    <Grid2 container spacing={2} sx={{ width: '100%' }}>
      <Grid2 item size={{ xs:12, md:6, lg:4}}>
        <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
          <Typography variant="h6">機能ツリー</Typography>
          <FunctionTree 
            data={functionTreeData} 
            onSelectFunction={handleSelectFunction} 
            popperPlacement="left-start"
          />
        </Paper>
      </Grid2>
      <Grid2 item size={{ xs:12, md:6, lg:8}}>
        <Paper style={{ height: '75vh', overflow: 'auto', padding: '0.5rem'}}>
          <Grid2 container alignItems="center" spacing={2}>
            <Grid2 item xs>
              <Typography variant="h6">単故障モード分析結果</Typography>
            </Grid2>
            <Grid2 item>
              <Button 
                variant="contained" 
                onClick={handleAnalyze} 
                disabled={loading}
                sx={{ mb: 2, alignSelf: 'flex-start' }}
              >
                {loading ? 'Analyzing...' : 'Analyze'}
              </Button>
              <SingleFailureModeDownload
                selectedFunction={selectedFunction}
                disabled={loading || !analysisResults}
              />
            </Grid2>
          </Grid2>
          {renderAnalysisResults()}
        </Paper>
      </Grid2>
    </Grid2>
  );
};

export default SingleFailureModeAnalysis;