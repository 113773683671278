import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useFunctionTree } from '../../FunctionTreeContext';
import { APIEndpoints } from '../../config';
import BottomPanelFlow from './BottomPanelFlow';
import SubFunctionNode from './SubfunctionNode';
import { Button, CircularProgress } from '@mui/material';
import window_incterchange from '../../images/window_interchange.svg';

const nodeTypes = {
  custom: SubFunctionNode,
};

const FunctionTreeVisualization = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { 
    selectedNode, 
    setSelectedNode, 
    integratedRequirements,
    setIntegratedRequirements,
    consolidatedResult,
    setConsolidatedResult,
    commonizedRequiremnt,
    setCommonizedRequirement,
    setMainFunctions,
    setSubFunctionDetails,
    importanceContent,
    setImportanceContent,
    productName,
    setProductName,
    isBottomPanelExpanded,
    setIsBottomPanelExpanded,
    user,
    authFetch, 
  } = useFunctionTree();

  const [isImplodingRequirements, setIsImplodingRequirements] = useState(false);
  const [isExtractingRequirements, setIsExtractingRequirements] = useState(false);
  const [isCommonizing, setIsCommonizing] = useState(false);
  const [functionTreeData, setFunctionTreeData] = useState(null);

  const replaceSpacesWithUnderscore = (str) => str.replace(/ /g, '_');

      // JSONファイルを読み込む
      // 機能ツリーデータを状態に保存
      useEffect(() => {
        const fetchFunctionTreeData = async () => {
          try {
            const data = await authFetch(APIEndpoints.FUNCTION_TREE);
            setFunctionTreeData(data);
            const { nodes, edges, mainFunctionsMap, subFunctionDetailsMap } = processData(data);
            setNodes(nodes);
            setEdges(edges);
            setMainFunctions(mainFunctionsMap);
            setSubFunctionDetails(subFunctionDetailsMap); 
            setProductName(data.product_name); 
          } catch (error) {
            console.error('Error fetching function tree data:', error);
          }
        };
    
        fetchFunctionTreeData();
      }, [authFetch, setMainFunctions, setSubFunctionDetails, setProductName]);

     // サブ機能の子機能（詳細機能）を取得
    const getChildFunctions = useCallback((subFunctionName) => {
      if (!functionTreeData || !functionTreeData.function_tree) {
        console.error('Function tree data is not available');
        return [];
      }

      for (const mainFunction of functionTreeData.function_tree) {
        const subFunction = mainFunction.sub_functions.find(sf => sf.sub_function === subFunctionName);
        if (subFunction) {
          return subFunction.details.map(detail => detail.function_level0);
        }
      }
      return [];
    }, [functionTreeData]);

    // ノードクリックでノードを選択　説明文の表示
    const onNodeClick = useCallback((event, node) => {
      setSelectedNode(prevNode => {
        
        const newSelectedNode = prevNode && prevNode.id === node.id ? null : node;
        // ノードクリックで枠線を赤でハイライト,選択解除でハイライト解除
        setNodes(nds => nds.map(n => ({
          ...n,
          style: {
            ...n.style,
            border: n.id === node.id && newSelectedNode ? '2px solid red' : 'none',
          },
        })));
        if (newSelectedNode && newSelectedNode.data.nodeType === 'detailFunction') {
          // 新しいノードが選択されたら integratedRequirements をリセット
          setIntegratedRequirements(null);
        }
        return newSelectedNode;
      });
    }, [setNodes,productName, setIntegratedRequirements]);

    
  // JSONデータをReact Flowのノードとエッジに変換する関数
  const processData = (data) => {
    const nodes = [];
    const edges = [];
    let id = 0;
    const mainFunctionsMap = {};
    const subFunctionDetailsMap = {};

    // ルートノードを追加
    nodes.push({
      id: (id++).toString(),
      data: { label: data.product_name },
      position: { x: 0, y: 0 },
      type: 'input',
      style: { background: '#C9C9FF', color: '#000' }  // ルートノードの色
    });

    // メイン機能を処理
    data.function_tree.forEach((mainFunction, mainIndex) => {
      const mainId = (id++).toString();
      nodes.push({
        id: mainId,
        data: {
           label: mainFunction.main_function,
           explanation: mainFunction.explanation || "説明文がありません。" 
           },
        position: { x: (mainIndex+0.25) * 350, y: 100 },
        style: {
          background: '#9999FF',
          color: '#000',
          fontWeight:'bold'
         }  // メイン機能のスタイル
      });
      edges.push({ id: `e${id}`, source: '0', target: mainId });

      // サブ機能を処理
      mainFunction.sub_functions.forEach((subFunction, subIndex) => {
        const subId = (id++).toString();
        nodes.push({
          id: subId,
          data: { 
            label: subFunction.sub_function,
            explanation: subFunction.explanation || "説明文がありません。",
            nodeType: 'subFunction',
            mainFunction: mainFunction.main_function
          },
          position: { x: (mainIndex+0.5) * 350, y: 200 + (subIndex) * 300 },
          style: { background: '#7070FF', color: '#fff',borderRadius: '5px' },  // サブ機能の色
          type: 'custom'
        });
        edges.push({ id: `e${id}`, source: mainId, target: subId });

        mainFunctionsMap[subFunction.sub_function] = mainFunction.main_function;
        //サブ機能と詳細機能の関係マップの準備
        subFunctionDetailsMap[subFunction.sub_function] = [];

        // 詳細機能を処理
        subFunction.details.forEach((detail, detailIndex) => {
          const detailId = (id++).toString();
          nodes.push({
            id: detailId,
            data: { 
            label: detail.function_level0,
            explanation: detail.説明 || "説明文がありません。" ,
            nodeType: 'detailFunction',
            mainFunction: mainFunction.main_function,  // メイン機能の情報を追加
            subFunction: subFunction.sub_function  // サブ機能の情報を追加
          },
            position: { x: (mainIndex + 0.75) * 350, y: 250 + subIndex * 300 + detailIndex * 50 },
            style: { borderRadius: '5px'}, 
            type: 'custom'
          });
          edges.push({ id: `e${id}`, source: subId, target: detailId });
          
          //サブ機能と詳細機能の関係マップに詳細機能を格納
          subFunctionDetailsMap[subFunction.sub_function].push({
            id: detailId,
            label: detail.function_level0
          });
          
        });
      });
    });
    return { nodes, edges, mainFunctionsMap, subFunctionDetailsMap };
  };

  // detailFunctionの場合の要件抽出処理
  const handleExtractRequirements = async () => {
    if (selectedNode && selectedNode.data.nodeType === 'detailFunction') {
      const { mainFunction, subFunction, label } = selectedNode.data;
      setIsExtractingRequirements(true);
      try {
        // 1. 要件の抽出
        await authFetch(`${APIEndpoints.EXTRACT_FUNCTIONAL_REQUIREMENTS}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction,
            function_level0_name: label
          }),
        });

        // 2. 抽出された要件の統合
        const integrateResult = await authFetch(`${APIEndpoints.INTEGRATE_FUNCTIONAL_REQUIREMENTS}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction,
            function_level0_name: label
          }),
        });

        // 統合された要件を状態にセット
        if (integrateResult.status === 'success') {
          const actualIntegratedData = await authFetch(`/api/integrated-requirements/${
            encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
            encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
            encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}/${
            encodeURIComponent(replaceSpacesWithUnderscore(label))}`);
          
          setIntegratedRequirements(actualIntegratedData);
        } else {
          throw new Error('Consolidation process did not return expected result');
        }
      } catch (error) {
        console.error('Error in extracting and integrating requirements:', error);
      } finally {
        setIsExtractingRequirements(false);
      }
    } else {
      console.error('No detail function node selected');
    }
  };

  // subFunctionの場合の要件抽出処理
  const handleImplodeRequirements = async () => {
    if (selectedNode && selectedNode.data.nodeType === 'subFunction') {
      const { mainFunction, label: subFunction } = selectedNode.data;
      const childFunctions = getChildFunctions(subFunction);
      
      if (childFunctions.length === 0) {
        console.error('No child functions found for the selected sub function');
        return;
      }

      setIsImplodingRequirements(true);
      try {
        // Step 1: Implode Functional Requirements
        await authFetch(`${APIEndpoints.IMPLODE_FUNCTIONAL_REQUIREMENTS_BATCH}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction,
            function_level0_names: childFunctions
          }),
        });
        
        // Wait for a short time to ensure file is written
        await new Promise(resolve => setTimeout(resolve, 1000));
        

        // Step 2: Integrate Imploded Requirements
        await authFetch(`${APIEndpoints.INTEGRATE_SUBFUNCTION_IMPLODED_REQUIREMENTS_BATCH}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction,
            function_level0_names: childFunctions
          }),
        });

        // Wait for a short time to ensure file is written
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Step 3: Consolidated Imploded Requirements
        const consolidatedResultData = await authFetch(`${APIEndpoints.CONSOLIDATE_SUBFUNCTION_IMPLODED_REQUIREMENTS}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction
          }),
        });

        // ここで、実際の統合された要件データを取得します
        if (consolidatedResultData.status === 'success') {
          const actualConsolidatedData = await authFetch(`/api/consolidated-upper-functions/${
            encodeURIComponent(replaceSpacesWithUnderscore(productName))}/${
            encodeURIComponent(replaceSpacesWithUnderscore(mainFunction))}/${
            encodeURIComponent(replaceSpacesWithUnderscore(subFunction))}`);
          
          setConsolidatedResult(actualConsolidatedData);
        } else {
          throw new Error('Consolidation process did not return expected result');
        }
      } catch (error) {
        console.error('Error in imploding, integrating, and consolidating requirements:', error);
        // Add error handling here
      } finally {
        setIsImplodingRequirements(false);
      }
    } else {
      console.error('No sub function node selected');
    }
  };
  
  // サブ機能の子機能の要件の抽出・統合・共通化
  const handleCommonizeRequirements = async () => {
    if (selectedNode && selectedNode.data.nodeType === 'subFunction') {
      setIsCommonizing(true);
      try {
        const { mainFunction, label: subFunction } = selectedNode.data;
        const result = await authFetch(`${APIEndpoints.COMMONIZE_REQUIREMENTS}?user_id=${user.uid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_name: productName,
            main_function_name: mainFunction,
            sub_function_name: subFunction
          }),
        });
        // Ensure the result has the expected structure
        if (result.status === 'success' && result.result && result.result.commonized_requirements) {
          setCommonizedRequirement(result.result);
        } else {
          console.error('Unexpected result structure:', result);
        }
      } catch (error) {
        console.error('Error commonizing requirements:', error);
      } finally {
        setIsCommonizing(false);
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth:'1200px',width:'100%', height: '80vh' }}>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', gap:10 }}>
      <Button 
          variant="contained" 
          disabled={!selectedNode || selectedNode.data.nodeType !== 'detailFunction' || isExtractingRequirements}
          onClick={handleExtractRequirements}
          sx={{ bgcolor: '#BF004D', '&:hover': { bgcolor: '#FF6699' } }}
        >
          {isExtractingRequirements ? <CircularProgress size={24} /> : '詳細機能の要件抽出'}
        </Button>
        <Button 
          variant="contained" 
          disabled={!selectedNode || selectedNode.data.nodeType !== 'subFunction' || isImplodingRequirements}
          onClick={handleImplodeRequirements}
          sx={{ bgcolor: '#BF004D', '&:hover': { bgcolor: '#FF6699' } }}
        >
          {isImplodingRequirements ? <CircularProgress size={24} /> : 'サブ機能の要件抽出'}
        </Button>
        <Button 
          variant="contained" 
          onClick={handleCommonizeRequirements}
          disabled={!selectedNode || selectedNode.data.nodeType !== 'subFunction'|| isCommonizing}
          sx={{ bgcolor: '#BF004D', '&:hover': { bgcolor: '#FF6699' } }}
        >
          {isCommonizing ? <CircularProgress size={24} /> : '構成機能の要件抽出'}
        </Button>

      </div>
      <div style={{ flex: 1, display: 'flex'}}>
       <div style={{ flex: 3, position: 'relative' }}>
        {/* 機能ツリー表示部 */}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            fitView
            nodesDraggable={false}
          >
            <Controls>
              <ControlButton onClick={() => setIsBottomPanelExpanded(!isBottomPanelExpanded)}>
                <img src={window_incterchange} alt="replace window size" style={{ width: '24px', height: '24px' }} />
              </ControlButton>
            </Controls>
            <MiniMap />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </div>

        {/*機能説明表示部 */}
        <div style={{ 
          flex: 1, 
          padding: '20px',
          overflowY: 'auto',
          borderLeft: '1px solid #ccc',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        }}>
          {selectedNode ? (
            <>
              <h4>{selectedNode.data.label}</h4>
              <p>{selectedNode.data.explanation || '説明文がありません'}</p>
            </>
          ) : (
            <p>機能を選択してください</p>
          )}
        </div>
      </div>
      
      {/* 要件ツリー表示部 */}
      <div style={{ display: 'flex', height: isBottomPanelExpanded ? '70%':'30%', borderTop: '1px solid #ccc' }}>
        <div style={{ flex: 3, height: '100%', overflow: 'hidden' }}>
          {selectedNode && (
            <BottomPanelFlow />
          )}
        </div>
        {/* 要件説明部 */}
        <div style={{ 
          flex: 1, 
          padding: '20px',
          overflowY: 'auto',
          borderLeft: '1px solid #ccc',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        }}>
          <h4>機能要件</h4>
          {importanceContent ? (
            <p>{importanceContent}</p>
          ) : (
            <p>機能要件を選択してください</p>
          )}
        </div>
      </div>
    </div>     
  );
};

export default FunctionTreeVisualization;