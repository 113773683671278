import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress, Paper, Grid2 } from '@mui/material';
import FunctionTree from './FunctionTree';
import { useFunctionTree } from '../FunctionTreeContext';
import { APIEndpoints } from '../config';
import { sanitizeDesignChangeTarget, sanitizeDesignChangeDescription } from '../utils/inputSanitizer';
import ImpactAnalysisDownload from './Download/ImpactAnalysisDownload';

const DesignChangeImpact = ({ functionTreeData }) => {
  const [target, setTarget] = useState('');
  const [description, setDescription] = useState('');
  const [impactAnalysis, setImpactAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {user, authFetch, refreshTokenBalance} = useFunctionTree();

  const handleAnalyze = async () => {
    if (!user) return;
    setLoading(true);
    setError(null);

    try {
      const data = await authFetch(`${APIEndpoints.IMPACT_ANALYSIS}?user_id=${user.uid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          target,
          description
        }),
      });
      await refreshTokenBalance();
      setImpactAnalysis(data.impact_analysis);
    } catch (error) {
      console.error('Error analyzing impact:', error);
      setError('影響分析中にエラーが発生しました。もう一度お試しください。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', mt: 0 }}>
      <Grid2 container spacing={2} sx={{ width: '100%' }}>
        <Grid2 item size={{ xs:12, md:6, lg:4}}>
          <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
            <Typography variant="h6">機能ツリー</Typography>
            <FunctionTree data={functionTreeData} />
          </Paper>
        </Grid2>
        <Grid2 item size={{ xs:12, md:6, lg:8}}>
          <Paper style={{ height: '75vh', overflow: 'auto', padding: '1rem'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">設計変更影響抽出</Typography>
              <ImpactAnalysisDownload
                target={target}
                disabled={loading || !impactAnalysis}
              />
            </Box>
            <TextField
              fullWidth
              label="変更対象（100文字以内）"
              value={target}
              onChange={(e) => setTarget(sanitizeDesignChangeTarget(e.target.value))}
              margin="normal"
            />
            <TextField
              fullWidth
              label="変更内容（1000文字以内）"
              value={description}
              onChange={(e) => setDescription(sanitizeDesignChangeDescription(e.target.value))}
              margin="normal"
              multiline
              rows={4}
            />
            <Button 
              variant="contained" 
              onClick={handleAnalyze} 
              disabled={loading || !target || !description}
              sx={{ mt: 2 }}
            >
              影響を分析
            </Button>
            {loading && <CircularProgress sx={{ mt: 2 }} />}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            {impactAnalysis && <ImpactAnalysisResult data={impactAnalysis} />}
          </Paper>
        </Grid2>
      </Grid2>
    </Box>  
  );
};

const ImpactAnalysisResult = ({ data }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1">直接影響を受ける機能:</Typography>
      <ul>
        {data.directly_affected_functions.map((func, index) => (
          <li key={index}>
            <Typography>
              {func.function_name}: {func.impact_reason}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography variant="subtitle1">間接的に影響を受ける機能:</Typography>
      <ul>
        {data.indirectly_affected_functions.map((func, index) => (
          <li key={index}>
            <Typography>
              {func.function_name}: {func.impact_reason}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography variant="subtitle1">相互依存関係:</Typography>
      <ul>
        {data.interdependencies.map((dep, index) => (
          <li key={index}>
            <Typography>
              {dep.primary_function}:
              <ul>
                {dep.related_functions.map((related, relIndex) => (
                  <li key={relIndex}>
                    {related.function_name}: {related.relationship}
                  </li>
                ))}
              </ul>
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default DesignChangeImpact;